import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { ServicesService } from '@app/services/services.service';
import { StorageService } from './storage.service';
import { first } from 'rxjs/operators';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  public notificationIsEnabled: boolean = false;
  constructor(
    private swPush: SwPush,
    private services: ServicesService,
    private storageService: StorageService
  ) {
    this.storageService
      .isReady()
      .pipe(first())
      .subscribe((data) => {
        this.notificationIsEnabled = this.storageService.get(
          'push_notification',
          false
        );
      });
  }

  public storePushNotification(
    key: string,
    title: string,
    body: string,
    url: string,
    scheduled_at: DateTime,
    delete_same: boolean = false
  ) {
    let data = JSON.stringify({
      unique_identifier: this.services.sessionService.unique_identifier,
      key: this.services.translateService.instant(key),
      title: this.services.translateService.instant(title),
      body: this.services.translateService.instant(body),
      url: url,
      scheduled_at: scheduled_at.toISO(),
      delete_same: delete_same,
    });

    this.services.apiService.post('pushnotifications', data).subscribe({
      next: (data: any) => {
        if (data.status_code == 200) {
          console.log('Add notification with success');
        } else {
        }
      },
      error: (e) => {
        console.error(e);
      },
      complete: () => {},
    });
  }

  public deletePushNotification(key: string) {
    this.services.apiService
      .delete(
        'pushnotifications?key=' +
          key +
          '&unique_identifier=' +
          this.services.sessionService.unique_identifier
      )
      .subscribe({
        next: (data: any) => {
          if (data.status_code == 200) {
            console.log('Deleted notification with success');
          } else {
          }
        },
        error: (e) => {
          console.error(e);
        },
        complete: () => {},
      });
  }

  public requestSubscription() {
    if (!this.swPush.isEnabled) {
      console.log('Notification is not enabled.');
      return;
    }

    this.swPush
      .requestSubscription({
        serverPublicKey:
          'BDRnAFwf3-DW157qkhnPTxpT55nX6uQY_urb5N4IWe7FPCGKVR6d-ibc2i4au_g0va9fXUoLho_JXKiH5ZbCOi0',
      })
      .then((_) => {
        let object = {
          ..._.toJSON(),
          ...{
            unique_identifier: this.services.sessionService.unique_identifier,
          },
        };
        let data = JSON.stringify(object);

        this.notificationIsEnabled = true;
        this.services.storageService.set('push_notification', true);
        this.services.apiService
          .post('pushnotifications/register', data)
          .subscribe((data: any) => {
            if (data.status_code == 200) {
              console.log(data);
              this.notificationIsEnabled = true;
              this.services.storageService.set('push_notification', false);
            } else {
              console.error(data);
              data['object'] = object;
              throw new Error(data);
            }
          });
      })
      .catch((_) => {
        console.log(_);
      });
  }
}
