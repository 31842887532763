import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { SessionService } from '@app/services/session.service';
import { ModalService } from '@app/services/modal.service';
import { UtilsService } from '@app/services/utils.service';
import { ApiService } from '@app/services/api.service';
import { StorageService } from '@app/services/storage.service';
import { first, tap, mergeMap, take } from 'rxjs/operators';
import { Debug } from '@app/class/debug';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class GeneralGuardService implements CanActivate {
  constructor(
    public modalService: ModalService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private sessionService: SessionService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.storageService
        .isReady()
        .pipe(first())
        .subscribe(
          (data) => {
            let nav_language = this.storageService.get('language', null);

            if (nav_language == null && route.queryParams['language']) {
              nav_language = route.queryParams['language'];
              this.storageService.set('language', nav_language);
            }

            if (nav_language == null) {
              nav_language = navigator.language;
              this.storageService.set('language', nav_language);
            }

            if (nav_language.includes('fr')) {
              this.translateService.setDefaultLang('fr');
              this.translateService.use('fr');
              this.sessionService.language = 'fr';
            } else {
              this.translateService.setDefaultLang('en');
              this.translateService.use('en');
              this.sessionService.language = 'en';
            }

            this.translateService.get('loading').subscribe(
              (translated: string) => {
                resolve(true);
              },
              (error) => {
                resolve(true);
              }
            );
          },
          (error) => {
            resolve(true);
          }
        );
    });

    //console.log("SUCCESS");
  }
}
