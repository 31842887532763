import { ComponentFactoryResolver, Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController, Platform, ToastController } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { PopoverInfoComponent } from '@app/components/popover-info/popover-info.component';
import { Debug } from '@app/class/debug';
import { ServicesService } from './services.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public isIOS: boolean = false;
  public isSafari: boolean = false;
  public isAndroid: boolean = false;
  public isPWA: boolean = false;
  public isNativeIOS: boolean = false;
  public isNativeAndroid: boolean = false;
  public os: string = 'windows';

  constructor(
    public translateService: TranslateService,
    public toastController: ToastController,
    public popoverController: PopoverController,
    public loadingController: LoadingController,
    public services: ServicesService,
    public platform: Platform,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      this.isSafari = true;
      this.os = 'macos';
    }

    if (this.platform.is('ios') || Debug.forceIOS) {
      this.isIOS = true;
      this.os = 'ios';
    }

    if (navigator.userAgent.includes('PWAShell') || Debug.forceNativeIOS) {
      this.isNativeIOS = true;
    }

    if (document.getElementsByClassName('plt-pwa').length > 0) {
      this.isPWA = true;
    }

    if (this.platform.is('android')) {
      this.isAndroid = true;
      this.os = 'android';
    }

    if (this.isAndroid && this.isPWA) {
      this.isNativeAndroid = true;
    }
  }

  public getAppUrl() {
    return window.location.origin;
  }

  async presentToast(
    str_info: string,
    icon: string = 'information',
    additionnal_class: any = null
  ) {
    try {
      const toast = await this.toastController.create({
        color: 'primary',
        cssClass: 'toast ' + additionnal_class,
        position: 'top',
        message: this.translateService.instant(str_info),
        duration: 3000,
        buttons: [
          {
            side: 'start',
            icon: icon,
            text: '',
            handler: () => {},
          },
        ],
      });
      toast.present();
    } catch (e) {}
  }

  clamp(num: number, min: number, max: number): number {
    return num <= min ? min : num >= max ? max : num;
  }

  randomNumber(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  ceil(number: number) {
    return Math.ceil(number);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  toLowercase(string) {
    return string.toLowerCase();
  }

  toUppercase(string) {
    return string.toUpperCase();
  }

  getWebsiteUrl(page: string = null) {
    let website = 'https://loveflame.app';
    console.log(this.services.sessionService.language);
    if (this.services.sessionService.language != 'en') {
      website += '/' + this.services.sessionService.language;
    }

    if (page != null) {
      website += '/' + page;
    }
    return website;
  }

  XOR(a, b) {
    return (a || b) && !(a && b);
  }

  cloneArray(array: Array<any>): Array<any> {
    return JSON.parse(JSON.stringify(array));
  }

  cloneObject(object) {
    return JSON.parse(JSON.stringify(object));
  }

  async presentAlertController(
    header,
    message,
    func_ok,
    button_ok_text,
    func_cancel = null,
    button_cancel_text = 'cancel',
    cssClasses = ['normal-alert']
  ) {
    const alert = await this.services.alertController.create({
      header: this.translateService.instant(header),
      message: this.translateService.instant(message),
      cssClass: cssClasses,
      buttons: [
        {
          text: this.translateService.instant(button_cancel_text),
          role: 'cancel',
          handler: () => {
            if (func_cancel != null) func_cancel();
          },
        },
        {
          text: this.translateService.instant(button_ok_text),
          role: 'confirm',
          handler: () => {
            if (func_ok != null) func_ok();
          },
        },
      ],
    });

    await alert.present();
  }

  shuffleArray(array: Array<any>) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }

  loading_popover: any = [];
  async presentLoading(message: any = null) {
    if (message == null) {
      message = this.translateService.instant('loading');
    }
    await this.loadingController
      .create({
        cssClass: 'loadingOpenSynaps',
        message: message,
        spinner: 'crescent',
        duration: 100000,
      })
      .then((a: any) => {
        a.present().then(() => {
          this.loading_popover.push(a);
        });
      });
  }

  async dismissLoading() {
    if (this.loading_popover.length > 0) {
      this.loading_popover[0].dismiss();
      this.loading_popover.shift();
    } else {
      let count = 0;
      let interval = setInterval(() => {
        if (this.loading_popover.length > 0) {
          this.loading_popover[0].dismiss();
          this.loading_popover.shift();
          clearInterval(interval);
        }
        if (count > 5) {
          clearInterval(interval);
        }
        count++;
      }, 200);
    }
  }

  getInstantTraduction(key: string, parameter = {}) {
    return this.translateService.instant(key, parameter);
  }

  stringToHash(str) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
      let chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  presentPopoverDidacticielToElement(vcRef, element_id, text, options) {
    let count_interval = 0;
    let interval = setInterval(() => {
      let element = document.getElementById(element_id);
      count_interval++;
      if (element != null) {
        clearInterval(interval);

        this.presentPopoverDidacticiel(vcRef, element, text, options);
      }
      if (count_interval > 4) {
        clearInterval(interval);
      }
    }, 500);
  }

  round(number) {
    return Math.round(number);
  }

  notZero(number, defaut = 1) {
    if (number == 0) {
      return defaut;
    }

    return number;
  }

  roundN(value, digits) {
    var tenToN = 10 ** digits;
    return /*Math.trunc*/ Math.round(value * tenToN) / tenToN;
  }

  percentage(number) {
    return Math.round(number * 100);
  }

  presentPopoverDidacticiel(content, target, message, options = null) {
    let _opt = {
      icon: 'information-circle',
      function: null,
      forceDisplay: false,
    };
    let opt = { ..._opt, ...options };
    setTimeout(() => {
      if (opt.forceDisplay || Debug.forcePopover) {
        this.presentPopoverInfo(content, target, message, opt);
      } else {
        let hash = this.stringToHash(message);
        this.services.storageService.storage
          .get(hash + '_popoverDidac')
          .then((val) => {
            if (val) {
              this.services.storageService.storage.set(
                hash + '_popoverDidac',
                val + 1
              );
            } else {
              this.services.storageService.storage.set(
                hash + '_popoverDidac',
                1
              );
              this.presentPopoverInfo(content, target, message, opt);
            }
          });
      }
    }, 100);
  }

  async presentPopoverInfo(content, target, message, options = {}) {
    if (content == null || target == null) {
      return;
    }
    let compFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        PopoverInfoComponent
      );
    let ref = content.createComponent(compFactory);
    ref.instance.target = target;
    ref.instance.message = this.getInstantTraduction(message);
    ref.instance.options = options;

    ref.instance.content = content.element.nativeElement;

    ref.instance.ngOnInit();
  }

  languageSelector(languages) {
    if (languages == null) {
      return '/!\\ Translation error, null';
    }

    var lang = this.translateService.currentLang;
    if (languages[lang] != null) {
      return languages[lang];
    }
    return '/!\\ Translation error';
  }

  getDebug(key: string) {
    if (environment.production) {
      return false;
    }

    return Debug[key];
  }

  abs(value: number) {
    return Math.abs(value);
  }

  getTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  getUniqueIdentifierSimple() {
    let fingerprint = [];
    fingerprint.push({ key: 'user_agent', value: navigator.userAgent });
    fingerprint.push({ key: 'language', value: navigator.language });
    fingerprint.push({ key: 'pixel_ratio', value: window.devicePixelRatio });
    fingerprint.push({
      key: 'hardware_concurrency',
      value: navigator.hardwareConcurrency,
    });
    fingerprint.push({
      key: 'resolution',
      value: [screen.width, screen.height],
    });
    fingerprint.push({
      key: 'available_resolution',
      value: [screen.availHeight, screen.availWidth],
    });
    fingerprint.push({
      key: 'timezone_offset',
      value: new Date().getTimezoneOffset(),
    });
    fingerprint.push({ key: 'session_storage', value: !window.sessionStorage });
    fingerprint.push({ key: 'local_storage', value: !window.localStorage });
    fingerprint.push({ key: 'indexed_db', value: !window.indexedDB });

    fingerprint.push({ key: 'navigator_platform', value: navigator.platform });
    fingerprint.push({ key: 'do_not_track', value: navigator.doNotTrack });
    fingerprint.push({ key: 'touch_support', value: navigator.maxTouchPoints });
    for (let i = 0; i < navigator.plugins.length; i++) {
      fingerprint.push({
        key: 'navigator_plugin_' + i,
        value: navigator.plugins[i].name,
      });
    }
    fingerprint.push({ key: 'cookie_enabled', value: navigator.cookieEnabled });
    var short_fingerprint = '';
    for (let j = 0; j < fingerprint.length; j++) {
      if (fingerprint[j] != null && fingerprint[j].value != null)
        short_fingerprint += fingerprint[j].value
          .toString()
          .toLowerCase()
          .substring(0, 1);
    }
    short_fingerprint += fingerprint.length;
    short_fingerprint += navigator.plugins.length;
    return short_fingerprint;
  }

  getNavigator() {
    let nVer = navigator.appVersion;
    let nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let fullVersion = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf('Opera')) != -1) {
      browserName = 'Opera';
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
      browserName = 'Microsoft Internet Explorer';
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
      browserName = 'Chrome';
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
      browserName = 'Safari';
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
      browserName = 'Firefox';
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(';')) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(' ')) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    let OSName = 'Unknown OS';
    if (navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows';
    if (navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS';
    if (navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX';
    if (navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux';

    return browserName + ' - ' + majorVersion + ' - ' + OSName;
  }
}
