import { Injectable } from '@angular/core';
import { ServicesService } from './services.service';
import { StorageService } from './storage.service';
import { first } from 'rxjs/operators';
import { Data } from '@app/class/data';
import { DateTime } from 'luxon';
import { DataService } from './data.service';
@Injectable({
  providedIn: 'root',
})
export class AiService {
  public ready: boolean = false;

  constructor(
    public services: ServicesService,
    private dataService: DataService
  ) {
    this.dataService.isReady().then((data) => {
      this.loadData();
      this.ready = true;
    });
  }

  isReady(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.ready) {
        resolve(true);
      } else {
        let interval = setInterval(() => {
          if (this.ready) {
            clearInterval(interval);
            resolve(true);
          }
        }, 200);
      }
    });
  }

  clearData() {
    this.my_missions = [];
    this.history_missions = [];
    this.malus_missions = [];
    this.ai_internal_data = [];
    this.utc_datetime_generated_mission = null;
  }

  public my_missions: any;
  public history_missions: any;
  public malus_missions: any;
  public ai_internal_data: any;
  public utc_datetime_generated_mission: string;

  private loadData() {
    this.my_missions = this.services.dataService.getKey('my_missions');
    if (this.my_missions == null) {
      this.my_missions = [];
    }

    this.history_missions =
      this.services.dataService.getKey('history_missions');
    if (this.history_missions == null) {
      this.history_missions = [];
    }

    this.ai_internal_data =
      this.services.dataService.getKey('ai_internal_data');
    if (this.ai_internal_data == null) {
      this.ai_internal_data = [];
    }

    this.malus_missions = this.services.dataService.getKey('malus_missions');
    if (this.malus_missions == null) {
      this.malus_missions = [];
    }

    this.utc_datetime_generated_mission = this.services.dataService.getKey(
      'utc_datetime_generated_mission'
    );
  }

  public week_days = [
    {
      name: 'monday',
      time_credit: 1,
      budget_credit: 1,
    },
    {
      name: 'tuesday',
      time_credit: 1,
      budget_credit: 1,
    },
    {
      name: 'wednesday',
      time_credit: 1,
      budget_credit: 1,
    },
    {
      name: 'thursday',
      time_credit: 1,
      budget_credit: 1,
    },
    {
      name: 'friday',
      time_credit: 2,
      budget_credit: 2,
    },
    {
      name: 'saturday',
      time_credit: 4,
      budget_credit: 4,
    },
    {
      name: 'sunday',
      time_credit: 4,
      budget_credit: 4,
    },
  ];

  public generateWeekMissions(
    bypass: boolean = false,
    no_alert: boolean = false
  ) {
    if (this.utc_datetime_generated_mission != null && !bypass) {
      let generated_date = DateTime.fromISO(
        this.utc_datetime_generated_mission
      ).toLocal();
      let next_monday = generated_date
        .startOf('week')
        .startOf('day')
        .plus({ week: 1 });

      let date_now = DateTime.now();

      if (date_now < next_monday) {
        console.log('Generation aborted because of date');

        return;
      }
    }

    console.log('Generate missions by AI');

    if (!bypass) {
      // Les missions non faites, on leur met un malus
      if (this.my_missions != null && this.my_missions.length > 0) {
        let unfinished_missions = this.my_missions.filter(
          (x) => x.status != 'finished'
        );
        for (let mission of unfinished_missions) {
          this.malus_missions.push(mission);
        }
        this.services.dataService.setKey('malus_missions', this.malus_missions);
      }
    }

    let categories = this.getCategoriesWeight();

    if (categories.length == 0) {
      console.log('Not enough data, no categories');
      return;
    }

    this.my_missions = [];

    let sum_time = 0;
    let sum_budget = 0;

    let count = 0;

    let max_time = 4;
    let max_budget = 4;
    switch (this.services.dataService.getKey('max_time', 3)) {
      case 1:
        max_time = 1;
        break;
      case 2:
        max_time = 2;
        break;
      case 3:
        max_time = 4;
        break;
    }
    switch (this.services.dataService.getKey('max_budget', 3)) {
      case 1:
        max_budget = 1;
        break;
      case 2:
        max_budget = 2;
        break;
      case 3:
        max_budget = 4;
        break;
    }

    let log: boolean = true;

    let number_missions = this.services.dataService.getKey('max_number', 7);
    for (let i = 0; i < number_missions; i++) {
      if (sum_time > 8) {
        max_time = 0;
      }

      if (sum_budget > 6) {
        max_budget = 0;
      }

      let type = count == 1 || count == 5 ? 'discovery' : 'priority';
      let filter_missions = this.findMission(
        categories,
        max_time,
        max_budget,
        type,
        log
      );
      log = false;

      let mission = filter_missions[0];

      sum_time += mission.time;
      sum_budget += mission.budget;
      mission['type'] = type;
      mission['status'] = 'waiting';
      this.my_missions.push(mission);
      count++;

      if (!no_alert)
        this.services.utilsService.presentToast(
          'calendar.newMissionGenerated',
          'calendar'
        );
    }

    // Find mission

    // Missions propositions

    this.services.utilsService.shuffleArray(this.my_missions);

    console.log('MISSIONS');

    console.log(this.my_missions);

    this.utc_datetime_generated_mission = DateTime.now().toISO();

    this.services.dataService.setKey(
      'utc_datetime_generated_mission',
      this.utc_datetime_generated_mission
    );

    this.services.dataService.setKey('my_missions', this.my_missions);

    this.services.eventService.publish('missions_updated', this.my_missions);
  }

  public changeMission(index) {
    let mission = this.my_missions[index];
    this.malus_missions.push(mission);

    let categories = this.getCategoriesWeight();

    let filter_missions = this.findMission(
      categories,
      mission.time,
      mission.budget,
      mission.type
    );

    let new_mission = filter_missions[0];
    new_mission['type'] = mission.type;
    new_mission['status'] = mission.status;

    this.my_missions[index] = new_mission;

    this.services.dataService.setKey('my_missions', this.my_missions);
    this.services.dataService.setKey('malus_missions', this.malus_missions);
  }

  public addCustomMission(mission) {
    mission['type'] = 'custom';
    mission['status'] = 'waiting';
    this.my_missions.push(mission);
    this.services.dataService.setKey('my_missions', this.my_missions);
  }

  public deleteMission(index) {
    this.my_missions.splice(index, 1);
    this.services.dataService.setKey('my_missions', this.my_missions);
  }

  public cancelMission(index) {
    let mission = this.my_missions[index];

    let index_history = this.history_missions.findIndex((_mission) => {
      // Assuming the mission object has a unique identifier or a set of properties that can be used to identify it
      return mission.utc_datetime === mission.utc_datetime;
    });

    this.history_missions.splice(index_history, 1);

    mission.utc_datetime = null;
    mission.status = 'waiting';

    this.services.dataService.setKey('my_missions', this.my_missions);
    this.services.dataService.setKey('history_missions', this.history_missions);
  }

  public addMalusMission(mission) {
    this.malus_missions.unshift(mission);
    this.services.dataService.setKey('malus_missions', this.malus_missions);
  }

  ignore_categories = ['week', 'weekend', 'interval'];

  public validateMission(index, appreciation: number, date: string = null) {
    console.log(index);
    console.log(this.my_missions);
    let mission = this.my_missions[index];
    if (date == null) {
      mission.utc_datetime = DateTime.now().toISO();
    } else {
      mission.utc_datetime = date;
    }
    mission.status = 'finished';
    mission.appreciation = appreciation;
    this.history_missions.unshift(mission);

    this.services.dataService.setKey('my_missions', this.my_missions);
    this.services.dataService.setKey('history_missions', this.history_missions);
  }

  public addHistoryMission(mission, appreciation: number, date: string) {
    mission['utc_datetime'] = date;
    mission['status'] = 'finished';
    mission['appreciation'] = appreciation;
    this.history_missions.unshift(mission);
    this.services.dataService.setKey('history_missions', this.history_missions);
  }

  getCategoriesWeight() {
    // Détermination du mode de calcul (PSY / DATA)

    let entries_number = 0;
    if (this.services.dataService.stats?.entries != null) {
      entries_number = this.services.dataService.stats?.entries;
    }

    let weight_psy = 0;
    let weight_data = 0;

    if (entries_number < 7) {
      weight_psy = 1;
      weight_data = 0;
    } else if (entries_number < 14) {
      weight_psy = 0.85;
      weight_data = 0.15;
    } else if (entries_number < 21) {
      weight_psy = 0.7;
      weight_data = 0.3;
    } else if (entries_number < 28) {
      weight_psy = 0.55;
      weight_data = 0.45;
    } else if (entries_number < 35) {
      weight_psy = 0.4;
      weight_data = 0.6;
    } else {
      weight_psy = 0.3;
      weight_data = 0.7;
    }
    let categories = [];

    // On récupère les bonnes catégories

    // 0.3
    const correlation_minimum = 0.3;

    //// Si on utilise des datas

    let stats = null;

    let focus_mode = 'quantity_quality';

    // Determination si quantity ou quality mode
    if (
      this.services.dataService.stats != null &&
      this.services.dataService.stats['number'] != null
    ) {
      if (this.services.dataService.stats['number'] != null) {
        stats = this.services.dataService.stats['number'];
        focus_mode = 'quantity';
      }

      if (
        this.services.dataService.stats['my_pleasure'] != null &&
        this.services.dataService.stats['my_pleasure'].average < 0.4
      ) {
        stats = this.services.dataService.stats['my_pleasure'];
        focus_mode = 'quality';
      }

      if (
        this.services.dataService.stats['partner_pleasure'] != null &&
        this.services.dataService.stats['partner_pleasure'].average < 0.4
      ) {
        stats = this.services.dataService.stats['partner_pleasure'];
        focus_mode = 'quality';
      }

      if (
        this.services.dataService.stats['number'] != null &&
        this.services.dataService.stats['number'].average < 0.4
      ) {
        stats = this.services.dataService.stats['number'];
        focus_mode = 'quantity';
      }
    }

    if (stats != null) {
      let featured_importances = stats['features_importance'].filter(
        (x) =>
          (x.value > correlation_minimum || x.value < -correlation_minimum) &&
          !this.ignore_categories.includes(x.key)
      );

      for (let feature_importance of featured_importances) {
        categories.push({
          name: feature_importance.key,
          weight: 3 * weight_data * Math.abs(feature_importance.value),
          priority: false,
        });
      }

      let spearman_correlations = stats['spearman'].filter(
        (x) =>
          (x.value > correlation_minimum || x.value < -correlation_minimum) &&
          !this.ignore_categories.includes(x.key)
      );

      for (let spearman_correlation of spearman_correlations) {
        categories.push({
          name: spearman_correlation.key,
          weight: 1 * weight_data * Math.abs(spearman_correlation.value),
          priority: false,
        });
      }

      let pearson_correlations = stats['pearson'].filter(
        (x) =>
          (x.value > correlation_minimum || x.value < -correlation_minimum) &&
          !this.ignore_categories.includes(x.key)
      );

      for (let pearson_correlation of pearson_correlations) {
        categories.push({
          name: pearson_correlation.key,
          weight: 1 * weight_data * Math.abs(pearson_correlation.value),
          priority: false,
        });
      }
    }

    //// Si on utilise le psy

    let love_attitudes = this.services.dataService.data['love_attitudes'];
    let role_repartition = this.services.dataService.data['role_repartition'];
    let love_languages = this.services.dataService.data['love_languages'];

    let mini_test = this.services.dataService.data['mini_test_lovelanguage'];

    if (mini_test != null && love_attitudes == null) {
      categories.push({
        name: mini_test,
        weight: 1 * weight_psy,
        priority: false,
      });
    }

    if (love_attitudes != null) {
      categories.push({
        name: love_attitudes['partner']['primary'][0].key,
        weight: 1 * weight_psy,
        priority: false,
      });
      categories.push({
        name: love_attitudes['partner']['secondary'][0].key,
        weight: 1 * weight_psy,
        priority: false,
      });
    }

    if (love_languages != null) {
      categories.push({
        name: love_languages['partner'][0].key,
        weight: 1 * weight_psy,
        priority: false,
      });
    }

    if (role_repartition != null) {
      let first = role_repartition['partner'][0];
      if (first.value > 10) {
        categories.push({
          name: role_repartition['partner'][0].key,
          weight: 4 * weight_psy,
          priority: false,
        });
      }
    }

    console.log('WEIGHT PSY : ' + weight_psy);
    console.log('WEIGHT DATA : ' + weight_data);
    console.log('CATEGORIES : ');
    console.log(categories);

    console.log('FOCUS MODE : ' + focus_mode);

    this.ai_internal_data = {
      weight_psy: weight_psy,
      weight_data: weight_data,
      categories: categories,
      focus_mode: focus_mode,
    };

    this.services.dataService.setKey('ai_internal_data', this.ai_internal_data);

    return categories;
  }

  findMission(categories, max_time, max_budget, type, log: boolean = false) {
    let all_missions = this.services.utilsService.cloneObject(Data.missions);

    // On enlève les missions déjà prévues
    all_missions = all_missions.filter((x) => {
      for (let mission of this.my_missions) {
        if (mission.title.en == x.title.en) {
          return false;
        }
      }
      return true;
    });
    let max_count = 0;
    for (let mission of all_missions) {
      let missions_in_history = this.history_missions.filter(
        (x) => x.title.en == mission.title.en
      );

      mission['utc_datetime'] = null;
      mission['count'] = missions_in_history.length;

      let count_appreciation = 0;
      let sum_appreciation = missions_in_history.reduce((sum, _mission) => {
        if (_mission['appreciation'] != null) {
          count_appreciation++;
          return sum + _mission['appreciation'];
        } else {
          return 0;
        }
      }, 0);

      let appreciation = 3;
      if (count_appreciation > 0) {
        appreciation = sum_appreciation / count_appreciation;
      }

      // Pour mettre à -1 / 1
      mission['appreciation_avg'] = (appreciation - 4) / 2;

      if (max_count < mission['count']) {
        max_count = mission['count'];
      }
      if (missions_in_history.length > 0)
        mission['utc_datetime'] = missions_in_history[0].utc_datetime;
    }

    // Classement

    let temp_missions = all_missions;
    let filter_missions = all_missions;
    for (let mission of filter_missions) {
      mission['array_categories'] = this.categoriesToArray(mission);
    }

    //// Filtre par temps

    temp_missions = filter_missions;
    filter_missions = filter_missions.filter((x) => x.time <= max_time);
    if (filter_missions.length < 1) {
      filter_missions = temp_missions;
    }

    //// Filtre par budget

    temp_missions = filter_missions;
    filter_missions = filter_missions.filter((x) => x.budget <= max_budget);
    if (filter_missions.length < 1) {
      filter_missions = temp_missions;
    }

    //// Filtre par categories

    ////// Prioritaire
    let priority_categories = categories.filter((x) => x.priority);
    temp_missions = filter_missions;
    filter_missions = filter_missions.filter((x) => {
      for (let cat of priority_categories) {
        if (x['array_categories'].includes(cat.name)) {
          return true;
        }
      }

      return false;
    });

    if (filter_missions.length < 1) {
      filter_missions = temp_missions;
    }

    ////// Secondaire

    let secondary_categories = categories.filter((x) => !x.priority);
    const weight_for_count = 3;
    const weight_for_categorie = 1;
    const weight_for_malus = 4;
    const weight_for_appreciation = 1;
    const bonus_weight_discover = 2;

    for (let mission of filter_missions) {
      let weight = 0;
      mission['weight_summary'] = [];
      for (let cat of secondary_categories) {
        if (mission['array_categories'].includes(cat.name)) {
          weight += cat.weight * weight_for_categorie;
          mission['weight_summary'].push({
            weight: cat.weight * weight_for_categorie,
            name: cat.name,
          });
        }
      }

      if (weight == 0 && type == 'discover') {
        weight += 1 * bonus_weight_discover;
      }

      // On regarde pour le nombre d'occurence
      let weight_count = 0;
      if (max_count != 0) weight_count = mission['count'] / max_count;

      mission['weight_summary'].push({
        weight: -(weight_count * weight_for_count),
        name: 'count',
      });

      // On regarde pour les malus

      let malus_missions = this.malus_missions.filter((x) => {
        if (mission.title.en == x.title.en) {
          return true;
        }

        return false;
      });

      mission['weight_summary'].push({
        weight: -(malus_missions.length * weight_for_malus),
        name: 'malus',
      });

      // On regarde pour l'appreciation
      let appreciation = mission['appreciation_avg'];

      mission['weight_summary'].push({
        weight: appreciation * weight_for_appreciation,
        name: 'appreciation',
      });

      // Calcul final du poids
      mission['weight'] =
        weight -
        weight_count * weight_for_count -
        malus_missions.length * weight_for_malus +
        appreciation * weight_for_appreciation;
    }
    filter_missions.sort((a, b) => {
      if (a['weight'] < b['weight']) {
        return 1;
      }

      if (a['weight'] > b['weight']) {
        return -1;
      }

      return 0;
    });

    if (log) {
      console.log('ALL MISSIONS');
      console.log(filter_missions);
    }

    return filter_missions;
  }

  categoriesToArray(mission) {
    let array = Object.entries(mission.categories).map(([key, value]) => {
      if (value === true) {
        return key;
      } else {
        return null;
      }
    });

    return array.filter((n) => n);
  }
}
