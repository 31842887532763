import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor(public translateService: TranslateService) {}

  getTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  getDayDifference(date_1: any, date_2: any) {
    if (!(date_1 instanceof DateTime)) {
      date_1 = DateTime.fromISO(date_1).toLocal();
    }

    if (!(date_2 instanceof DateTime)) {
      date_2 = DateTime.fromISO(date_1).toLocal();
    }

    date_1 = date_1.startOf('day');
    date_2 = date_2.startOf('day');

    return date_1.diff(date_2, ['days']).toObject().days;
  }

  getDayDifferenceFromToday(date: any) {
    if (!(date instanceof DateTime)) {
      date = DateTime.fromISO(date).toLocal().startOf('day');
    }

    let date_today = DateTime.now().startOf('day');

    return date_today.diff(date, ['days']).toObject().days;
  }

  samedayThanToday(date) {
    return this.samedayThanDay(date, DateTime.now());
  }

  samedayThanDay(date_1: any, date_2: any) {
    if (!(date_1 instanceof DateTime)) {
      date_1 = DateTime.fromISO(date_1).toLocal();
    }

    if (!(date_2 instanceof DateTime)) {
      date_2 = DateTime.fromISO(date_1).toLocal();
    }
    return date_1.hasSame(date_2, 'day');
  }

  getMonthName(number: number) {
    let months = [
      this.translateService.instant('month.january'),
      this.translateService.instant('month.february'),
      this.translateService.instant('month.march'),
      this.translateService.instant('month.april'),
      this.translateService.instant('month.may'),
      this.translateService.instant('month.june'),
      this.translateService.instant('month.july'),
      this.translateService.instant('month.august'),
      this.translateService.instant('month.september'),
      this.translateService.instant('month.october'),
      this.translateService.instant('month.november'),
      this.translateService.instant('month.december'),
    ];

    return months[number - 1];
  }

  getNameDayFromToday(date: any) {
    if (!(date instanceof DateTime)) {
      date = DateTime.fromISO(date).toLocal();
    }

    let today = DateTime.now().startOf('day');

    let numberDay = 0;
    numberDay = today.diff(date, 'days').toObject().days!;
    if (numberDay > 0) {
      numberDay = Math.floor(numberDay);
    }
    if (numberDay < 0) {
      numberDay = Math.ceil(numberDay);
    }
    if (numberDay == 0) {
      return this.translateService.instant('week_day.today');
    }

    if (numberDay == 1) {
      return this.translateService.instant('week_day.yesterday');
    }

    if (numberDay == -1) {
      return this.translateService.instant('week_day.tomorrow');
    }

    let days = [
      this.translateService.instant('week_day.monday'),
      this.translateService.instant('week_day.tuesday'),
      this.translateService.instant('week_day.wednesday'),
      this.translateService.instant('week_day.thursday'),
      this.translateService.instant('week_day.friday'),
      this.translateService.instant('week_day.saturday'),
      this.translateService.instant('week_day.sunday'),
    ];

    if (numberDay < -1 && numberDay > -7) {
      let day_string = days[date.weekday - 1];

      return this.translateService.instant('week.next', { day: day_string });
    }

    if (numberDay > 1 && numberDay < 7) {
      let day_string = days[date.weekday - 1];
      return this.translateService.instant('week.before', {
        day: day_string,
      });
    }

    if (numberDay >= 7) {
      return this.translateService.instant('week_day.before', {
        day: numberDay,
      });
    }
    if (numberDay <= -7) {
      return this.translateService.instant('week_day.after', {
        day: Math.abs(numberDay),
      });
    }

    return this.translateService.instant('week_day.date', {
      day: date.day,
      month: date.month + 1,
    });
  }
}
