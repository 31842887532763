import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'faq-modal',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqPageModule),
  },
  {
    path: 'login-modal',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'contact-modal',
    loadChildren: () =>
      import('./contact/contact.module').then((m) => m.ContactPageModule),
  },
  {
    path: 'premium-modal',
    loadChildren: () =>
      import('./premium/premium.module').then((m) => m.PremiumPageModule),
  },
  {
    path: 'register-modal',
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterPageModule),
  },
  {
    path: 'debug-modal',
    loadChildren: () =>
      import('./debug/debug.module').then((m) => m.DebugPageModule),
  },
  {
    path: 'explication-modal',
    loadChildren: () =>
      import('./explication/explication.module').then(
        (m) => m.ExplicationPageModule
      ),
  },
  {
    path: 'review-modal',
    loadChildren: () =>
      import('./review/review.module').then((m) => m.ReviewPageModule),
  },

  {
    path: 'code-modal',
    loadChildren: () =>
      import('./code/code.module').then((m) => m.CodePageModule),
  },  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'pay-question',
    loadChildren: () => import('./pay-question/pay-question.module').then( m => m.PayQuestionPageModule)
  },
  {
    path: 'making-of',
    loadChildren: () => import('./making-of/making-of.module').then( m => m.MakingOfPageModule)
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModalsRoutingModule {}
