<ion-content>
  <ion-fab vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button color="white" size="small" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="container">
    <div class="middle allWidth flex-col-vertical-center">
      <ion-icon
        *ngIf="image_or_icon == null"
        name="information-circle"
        size="large"
      ></ion-icon>
      <ng-container *ngIf="image_or_icon != null">
        <ion-icon
          *ngIf="!image_or_icon.includes('/')"
          [name]="image_or_icon"
          size="large"
        ></ion-icon>
        <ion-img
          class="illustration"
          *ngIf="image_or_icon.includes('/')"
          [src]="image_or_icon"
        ></ion-img>
      </ng-container>

      <h1 class="title-modal">{{title | translate}}</h1>
      <app-divider></app-divider>
      <div class="allWidth ion-margin-top">
        <app-text-html
          [html]="content | translate"
          [limitHeight]="false"
          [canOpenMore]="false"
          alignment="justify"
        ></app-text-html>
      </div>
    </div>
  </div>
</ion-content>
