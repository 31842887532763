import { Injectable } from '@angular/core';
import { Debug } from '@app/class/debug';
import { Storage } from '@ionic/storage-angular';
import { Observable, of, from, Observer } from 'rxjs';
import { EncryptionService } from './encryption.service';
import { setThrowInvalidWriteToSignalError } from '@angular/core/primitives/signals';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public ready: boolean = false;
  needToSave: boolean = false;
  object: any = {};
  permanent_object: any = {};
  public storage!: any;
  constructor(
    private _storage: Storage,
    private encryptionService: EncryptionService
  ) {
    this.initStorage();
  }

  async initStorage() {
    const storage = await this._storage.create();
    this.storage = storage;

    setInterval(() => {
      if (this.needToSave) {
        this.needToSave = false;
        this.save();
      }
    }, 500);

    this.loadKey();
    this.loadStorage();
  }

  async loadKey() {
    // DEPRECATED : 13/09/2024 - REMOVE 13/10/2024
    let key = await this.storage.get('key');

    if (key == null) {
      return;
      // key = this.encryptionService.generateKey();
      //await this.storage.set('key', key);
    }

    this.encryptionService.setKey(key);
  }

  async loadStorage() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);

    this.storage.get('love-flame').then((data: any) => {
      if (data) {
        try {
          if (this.encryptionService.is_used) {
            data = this.encryptionService.decryptData(data);
          }

          this.object = JSON.parse(data);
        } catch (exception) {
          console.log(data);
          console.log('PROBLEM DATA');
        }
      }
      if (this.object == null) {
        this.object = {};
      }
      this.ready = true;
    });
  }

  isReady(): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      if (this.ready) {
        observer.next(true);
        observer.complete();
      } else {
        let interval = setInterval(() => {
          if (this.ready) {
            clearInterval(interval);
            observer.next(true);
            observer.complete();
          }
        }, 200);
      }
    });
  }

  set(key: string, data: any) {
    this.needToSave = true;
    this.object[key] = data;
  }

  get(key: string, defaut = null) {
    if (this.object[key] != null) {
      return this.object[key];
    } else {
      return defaut;
    }
  }

  check(key: string) {
    return this.object[key] != null;
  }

  delete(key: string) {
    this.object[key] = null;
    this.needToSave = true;
  }

  logContent() {
    Debug.log(JSON.stringify(this.object));
  }

  deleteAll() {
    this.object = {};
    console.log('Deleted all storage infos');
    this.save();
  }

  save() {
    let data = JSON.stringify(this.object);
    this.storage.remove('key');
    this.encryptionService.is_used = false;

    this.storage.set('love-flame', data);
  }
}
