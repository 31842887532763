<ion-content>
  <ion-fab vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button color="white" size="small" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="container">
    <div class="middle flex-col-vertical-center">
      <ion-icon name="cloud-download" size="large"></ion-icon>
      <h1>{{'install.title' | translate}}</h1>
      <app-divider></app-divider>
      <p class="ion-text-center">{{'install.explication' | translate}}</p>
      <div class="allWidth flex-col-vertical-center">
        <ion-img
          class="button_store hover"
          (click)="openPlayStore()"
          src="assets/images/store/icon_GooglePlay.png"
        ></ion-img>
        <ion-img
          class="button_store hover ion-margin-top"
          (click)="openAppStore()"
          src="assets/images/store/icon_AppStore.png"
        ></ion-img>
      </div>
      <div class="allWidth flex-col-center">
        <ion-toggle
          class="ion-double-margin-top"
          [checked]="false"
          (ionChange)="dontDisplay()"
          >{{'install.dontDisplay' | translate}}</ion-toggle
        >
      </div>
    </div>
  </div>
</ion-content>
