<ion-content>
  <ion-fab vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button color="white" size="small" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <div class="container">
    <div class="middle allWidth">
      <ng-container *ngIf="mode == 'buy'">
        <div
          class="trial-header allWidth"
          style="background-color: var(--ion-color-success)"
          *ngIf="this.services.sessionService.free_trial != null && !this.services.sessionService.has_buyed"
        >
          {{'premium.enjoyTrial' |
          translate:{day:this.services.utilsService.ceil(this.services.sessionService.free_trial.date_staying)}
          }}
        </div>

        <div
          class="trial-header allWidth"
          *ngIf="this.services.sessionService.free_trial == null && !this.services.sessionService.has_buyed"
        >
          {{'premium.trialFinished' | translate }}
        </div>

        <h1 class="ion-double-margin-top">{{'premium.title' | translate}}</h1>
        <app-divider></app-divider>
        <div class="allWidth first-explication">
          <ion-img
            class="img-giorgio"
            src="assets/images/characters/specific/cupidon.png"
          ></ion-img>
          <p [innerHtml]="'premium.explication' | translate"></p>
        </div>
        <h2 class="ion-text-center">{{'premium.chooseOffer' | translate}}</h2>

        <div
          class="allWidth flex-col-vertical-center ion-margin-top ion-margin-bottom"
          *ngIf="discount"
        >
          <span class="indicator"
            >{{'premium.discountExplication' | translate:{x:discount.percentage}
            }}</span
          >
        </div>
        <div
          class="allWidth flex-col-vertical-center ion-margin-top"
          *ngIf="all_currencies"
        >
          <div class="currency-change">
            <ion-select
              (ionChange)="loadPrice($event.detail.value)"
              interface="popover"
              value="{{current_currencie}}"
              placeholder="{{'premium.changeCurrencie'}}"
            >
              <ion-select-option
                *ngFor="let currency of all_currencies"
                [value]="currency"
                >{{this.services.utilsService.toUppercase(currency)}}</ion-select-option
              >
            </ion-select>
          </div>
        </div>

        <app-loading [object]="price"></app-loading>
        <ion-row class="containerPricing" *ngIf="price">
          <ion-col size="12" size-lg="4">
            <ion-card class="hover" (click)="buy('subscription_monthly')">
              <ion-card-header>
                <div class="discount" *ngIf="discount">
                  <span> -{{discount.percentage}}%</span>
                </div>

                <ion-card-title
                  >{{'premium.subscription_monthly' |
                  translate}}</ion-card-title
                >

                <ion-card-subtitle
                  *ngIf="price"
                  [class.line-trough]="discount != null"
                  >{{this.price.subscription_monthly |
                  price:{currency:this.current_currencie} }} /
                  {{'premium.everyMonth' | translate}}</ion-card-subtitle
                >

                <ion-card-subtitle
                  *ngIf="price && discount"
                  class="discount-price"
                  >{{this.services.utilsService.roundN(this.price.subscription_monthly
                  - this.price.subscription_monthly * (discount.percentage /
                  100),2) | price:{currency:this.current_currencie} }} /
                  {{'premium.everyMonth' | translate}}</ion-card-subtitle
                >
              </ion-card-header>
              <ion-card-content>
                <ion-list>
                  <ion-item>
                    <ion-icon slot="start" name="infinite-outline"></ion-icon>
                    <ion-label
                      >{{'premium.completeAccess' | translate}}</ion-label
                    >
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="calendar-outline"></ion-icon>
                    <ion-label>{{'premium.allMissions' | translate}}</ion-label>
                  </ion-item>

                  <ion-item>
                    <ion-icon
                      slot="start"
                      name="stats-chart-outline"
                    ></ion-icon>
                    <ion-label>{{'premium.allStats' | translate}}</ion-label>
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="flame-outline"></ion-icon>
                    <ion-label
                      >{{'premium.personalizedAdvices' | translate}}</ion-label
                    >
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="help-buoy-outline"></ion-icon>
                    <ion-label
                      >{{'premium.premiumSupport' | translate}}</ion-label
                    >
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="reload-outline"></ion-icon>
                    <ion-label>{{'premium.update' | translate}}</ion-label>
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="tv-outline"></ion-icon>
                    <ion-label
                      >{{'premium.multipleDevices' | translate}}</ion-label
                    >
                  </ion-item>
                </ion-list>
              </ion-card-content>

              <div class="allWidth flex-col-center ion-margin-bottom">
                <ion-button color="primary" shape="round" size="large">
                  {{'premium.buy' | translate}}
                  <ion-icon
                    name="chevron-forward-outline"
                    slot="end"
                  ></ion-icon>
                </ion-button>
              </div>
            </ion-card>
          </ion-col>
          <ion-col size="12" size-lg="4">
            <ion-card class="hover" (click)="buy('subscription_yearly')">
              <ion-card-header>
                <div class="discount" *ngIf="discount">
                  <span> -{{discount.percentage}}%</span>
                </div>

                <ion-card-title
                  >{{'premium.subscription_yearly' | translate}}</ion-card-title
                >

                <ion-card-subtitle
                  *ngIf="price"
                  [class.line-trough]="discount != null"
                  >{{this.services.utilsService.roundN(this.price.subscription_yearly
                  / 12, 2) | price:{currency:this.current_currencie} }} /
                  {{'premium.everyMonth' | translate}}</ion-card-subtitle
                >

                <ion-card-subtitle
                  *ngIf="price && discount"
                  class="discount-price"
                  >{{this.services.utilsService.roundN((this.price.subscription_yearly
                  - this.price.subscription_yearly * (discount.percentage /
                  100)) / 12,2) | price:{currency:this.current_currencie} }} /
                  {{'premium.everyMonth' | translate}}</ion-card-subtitle
                >
                <p class="price-yearly">
                  ({{this.services.utilsService.roundN((this.price.subscription_yearly
                  - this.price.subscription_yearly * (discount.percentage /
                  100)),2) | price:{currency:this.current_currencie} }} /
                  {{'premium.everyYear' | translate}})
                </p>
              </ion-card-header>
              <ion-card-content>
                <ion-list>
                  <ion-item>
                    <ion-icon slot="start" name="infinite-outline"></ion-icon>
                    <ion-label
                      >{{'premium.completeAccess' | translate}}</ion-label
                    >
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="calendar-outline"></ion-icon>
                    <ion-label>{{'premium.allMissions' | translate}}</ion-label>
                  </ion-item>

                  <ion-item>
                    <ion-icon
                      slot="start"
                      name="stats-chart-outline"
                    ></ion-icon>
                    <ion-label>{{'premium.allStats' | translate}}</ion-label>
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="flame-outline"></ion-icon>
                    <ion-label
                      >{{'premium.personalizedAdvices' | translate}}</ion-label
                    >
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="help-buoy-outline"></ion-icon>
                    <ion-label
                      >{{'premium.premiumSupport' | translate}}</ion-label
                    >
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="reload-outline"></ion-icon>
                    <ion-label>{{'premium.update' | translate}}</ion-label>
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="tv-outline"></ion-icon>
                    <ion-label
                      >{{'premium.multipleDevices' | translate}}</ion-label
                    >
                  </ion-item>
                </ion-list>
              </ion-card-content>

              <div class="allWidth flex-col-center ion-margin-bottom">
                <ion-button color="primary" shape="round" size="large">
                  {{'premium.buy' | translate}}
                  <ion-icon
                    name="chevron-forward-outline"
                    slot="end"
                  ></ion-icon>
                </ion-button>
              </div>
            </ion-card>
          </ion-col>
          <ion-col size="12" size-lg="4">
            <ion-card class="hover" (click)="buy('lifetime')">
              <ion-card-header>
                <div class="discount" *ngIf="discount">
                  <span> -{{discount.percentage}}%</span>
                </div>
                <ion-card-title
                  >{{'premium.lifetime' | translate}}</ion-card-title
                >
                <ion-card-subtitle
                  *ngIf="price"
                  [class.line-trough]="discount != null"
                  >{{this.price.lifetime |
                  price:{currency:this.current_currencie} }} /
                  {{'premium.unique_buy' | translate}}</ion-card-subtitle
                >

                <ion-card-subtitle
                  *ngIf="price && discount"
                  class="discount-price"
                  >{{this.services.utilsService.roundN(this.price.lifetime -
                  this.price.lifetime * (discount.percentage / 100),2) |
                  price:{currency:this.current_currencie} }} /
                  {{'premium.unique_buy' | translate}}</ion-card-subtitle
                >
              </ion-card-header>
              <ion-card-content>
                <ion-list>
                  <ion-item>
                    <ion-icon slot="start" name="infinite-outline"></ion-icon>
                    <ion-label
                      >{{'premium.completeAccess' | translate}}</ion-label
                    >
                  </ion-item>
                  <ion-item>
                    <ion-icon slot="start" name="hourglass-outline"></ion-icon>
                    <ion-label
                      >{{'premium.lifetimeAccess' | translate}}</ion-label
                    >
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="calendar-outline"></ion-icon>
                    <ion-label>{{'premium.allMissions' | translate}}</ion-label>
                  </ion-item>

                  <ion-item>
                    <ion-icon
                      slot="start"
                      name="stats-chart-outline"
                    ></ion-icon>
                    <ion-label>{{'premium.allStats' | translate}}</ion-label>
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="flame-outline"></ion-icon>
                    <ion-label
                      >{{'premium.personalizedAdvices' | translate}}</ion-label
                    >
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="help-buoy-outline"></ion-icon>
                    <ion-label
                      >{{'premium.premiumSupport' | translate}}</ion-label
                    >
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="reload-outline"></ion-icon>
                    <ion-label>{{'premium.update' | translate}}</ion-label>
                  </ion-item>

                  <ion-item>
                    <ion-icon slot="start" name="tv-outline"></ion-icon>
                    <ion-label
                      >{{'premium.multipleDevices' | translate}}</ion-label
                    >
                  </ion-item>
                </ion-list>
              </ion-card-content>

              <div class="allWidth flex-col-center ion-margin-bottom">
                <ion-button color="primary" shape="round" size="large">
                  {{'premium.buy' | translate}}
                  <ion-icon
                    name="chevron-forward-outline"
                    slot="end"
                  ></ion-icon>
                </ion-button>
              </div>
            </ion-card>
          </ion-col>
        </ion-row>

        <ion-img
          class="ion-margin-bottom ion-padding"
          style="max-width: 400px; margin: auto"
          src="assets/images/premium/banniere-paiement-disponible.png"
        ></ion-img>

        <h2 class="ion-text-center" style="margin-top: 50px">
          {{'premium.joinTheTeam' | translate}}
        </h2>

        <ion-img
          class="img-giorgio"
          src="assets/images/characters/specific/star.png"
        ></ion-img>

        <ion-row class="container-review">
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <div class="allWidth flex-col-center stars">
              <ion-icon
                name="star"
                *ngFor="let item of 5 | enumerate; let i = index"
              ></ion-icon>
            </div>
            <p
              class="ion-text-center"
              [innerHtml]="'premium.review1' | translate"
            ></p>
            <span class="ion-text-center">Greg</span>
          </ion-col>
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <div class="allWidth flex-col-center stars">
              <ion-icon
                name="star"
                *ngFor="let item of 5 | enumerate; let i = index"
              ></ion-icon>
            </div>
            <p
              class="ion-text-center"
              [innerHtml]="'premium.review2' | translate"
            ></p>
            <span class="ion-text-center">Alan</span>
          </ion-col>
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <div class="allWidth flex-col-center stars">
              <ion-icon
                name="star"
                *ngFor="let item of 5 | enumerate; let i = index"
              ></ion-icon>
            </div>
            <p
              class="ion-text-center"
              [innerHtml]="'premium.review3' | translate"
            ></p>
            <span class="ion-text-center">Sarah</span>
          </ion-col>
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <div class="allWidth flex-col-center stars">
              <ion-icon
                name="star"
                *ngFor="let item of 5 | enumerate; let i = index"
              ></ion-icon>
            </div>
            <p
              class="ion-text-center"
              [innerHtml]="'premium.review4' | translate"
            ></p>
            <span class="ion-text-center">Johnny</span>
          </ion-col>
        </ion-row>
        <h2 class="ion-text-center">{{'premium.someQuestions' | translate}}</h2>

        <ion-img
          class="img-giorgio"
          src="assets/images/characters/specific/math.png"
        ></ion-img>
        <ion-row class="container-questions">
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <span class="title-question"
              >« {{'premium.questionTitle1' | translate}} »</span
            >
            <div class="container-response flex-col-vertical-center">
              <div class="icon flex-col-vertical-center">
                <ion-icon name="infinite"></ion-icon>
              </div>
              <span class="title-response"
                >{{'premium.responseTitle1' | translate}}</span
              >
              <p [innerHtml]="'premium.response1' | translate"></p>
            </div>
          </ion-col>
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <span class="title-question"
              >« {{'premium.questionTitle2' | translate}} »</span
            >
            <div class="container-response flex-col-vertical-center">
              <div class="icon flex-col-vertical-center">
                <ion-icon name="shield-checkmark"></ion-icon>
              </div>
              <span class="title-response"
                >{{'premium.responseTitle2' | translate}}</span
              >
              <p [innerHtml]="'premium.response2' | translate"></p>
            </div>
          </ion-col>
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <span class="title-question"
              >« {{'premium.questionTitle3' | translate}} »</span
            >
            <div class="container-response flex-col-vertical-center">
              <div class="icon flex-col-vertical-center">
                <ion-icon name="refresh"></ion-icon>
              </div>
              <span class="title-response"
                >{{'premium.responseTitle3' | translate}}</span
              >
              <p [innerHtml]="'premium.response3' | translate"></p>

              <ion-img
                class="ion-margin-bottom"
                style="
                  max-width: 400px;
                  margin: auto;
                  border-radius: 10px;
                  overflow: hidden;
                "
                src="assets/images/premium/update-subscription.gif"
              ></ion-img>
            </div>
          </ion-col>

          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <span class="title-question"
              >« {{'premium.questionTitle4' | translate}} »</span
            >
            <div class="container-response flex-col-vertical-center">
              <div class="icon flex-col-vertical-center">
                <ion-icon name="cash"></ion-icon>
              </div>
              <span class="title-response"
                >{{'premium.responseTitle4' | translate}}</span
              >
              <p [innerHtml]="'premium.response4' | translate"></p>

              <ion-img
                class="ion-margin-bottom"
                style="
                  max-width: 400px;
                  margin: auto;
                  border-radius: 10px;
                  overflow: hidden;
                "
                src="assets/images/premium/new-relationship.gif"
              ></ion-img>
            </div>
          </ion-col>
        </ion-row>

        <h2 class="ion-text-center">{{'premium.otherQuestion' | translate}}</h2>
        <p class="ion-text-center">
          {{'premium.contactExplication' | translate}}
        </p>

        <div class="allWidth flex-col-vertical-center ion-margin-bottom">
          <ion-button
            color="primary"
            shape="round"
            class="ion-margin-bottom"
            (click)="this.services.modalService.openFaq();"
          >
            {{'profil.faq' | translate}}
            <ion-icon name="help-outline" slot="end"></ion-icon>
          </ion-button>
          <ion-button color="primary" shape="round" (click)="openContact()">
            {{'premium.contact' | translate}}
            <ion-icon name="send-outline" slot="end"></ion-icon>
          </ion-button>
        </div>

        <div class="allWidth flex-col-center ion-margin-top ion-padding-bottom">
          <ion-text color="medium">
            <span class="underline hover" (click)="openSaleAgreement()"
              >{{'premium.saleAgreement' | translate}}</span
            >
          </ion-text>
        </div>
      </ng-container>
      <ng-container *ngIf="mode == 'success'">
        <div class="allWidth success">
          <ion-img
            style="max-width: 350px; margin: auto"
            src="assets/images/characters/specific/gourmand.png"
          ></ion-img>
          <p
            class="ion-text-center"
            [innerHtml]="'premium.success' | translate"
          ></p>
          <div class="flex-col-center allWidth">
            <ion-button color="primary" shape="round" (click)="dismiss()">
              {{'premium.close' | translate}}
              <ion-icon name="checkmark" slot="end"></ion-icon>
            </ion-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ion-content>
