export class Data {
  public static missions = [
    {
      title: {
        fr: 'Préparer et amener le petit déjeuner au lit',
        en: 'Prepare and bring the lunch in bed',
      },
      budget: 1,
      time: 0,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire un massage de 10 minutes du dos',
        en: 'Do a 10-minute back massage',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: true,
        service: true,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: false,
        duree: true,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment faire un massage du dos ?',
            type: 'link',
            url: 'https://www.lesjardins-suspendus.com/comment-faire-un-bon-massage-du-dos/#:~:text=Pour%20la%20technique%2C%20massez%20de,vos%20doigts%20et%20descendez%20doucement.',
          },
          en: {
            title: 'How to make a good back massage ?',
            type: 'link',
            url: 'https://www.drugs.com/cg/how-to-give-a-back-massage.html',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Préparer un petit cadeau',
        en: 'Offer a little gift',
      },
      budget: 1,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller manger dans un restaurant',
        en: 'Go eat in a restaurant',
      },
      budget: 2,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Ecrire un poème',
        en: 'Write a poem',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: true,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment écrire un poème ?',
            type: 'link',
            url: 'https://www.edilivre.com/8-conseils-pour-ecrire-un-poeme/',
          },
          en: {
            title: 'How to write a poem ?',
            type: 'link',
            url: 'https://www.grammarly.com/blog/how-to-write-a-poem/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Aller boire un verre ensemble',
        en: 'Go have a drink together ',
      },
      budget: 1,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
      },
      action: null,
    },
    {
      title: {
        fr: "Nettoyer l'appartement",
        en: 'Clean your flat',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: true,
        moment: false,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: false,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
      },
      action: null,
    },
    {
      title: {
        fr: 'Préparer un bon repas',
        en: 'Prepare a good meal',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '15 idées recettes',
            type: 'link',
            url: 'https://www.marieclaire.fr/cuisine/diner-romantique-15-recettes-faciles-pour-impressionner-son-date,1457290.asp',
          },
          en: {
            title: '32 recipes ideas',
            type: 'link',
            url: 'https://www.delicious.com.au/recipes/collections/gallery/70-recipes-for-a-romantic-date-night-at-home/9d4s0yiz',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Aller faire les commissions',
        en: 'Go make food groceries',
      },
      budget: 2,
      time: 1,
      categories: {
        touch: false,
        service: true,
        moment: false,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: false,
        eros: false,
        ludus: false,
        storge: true,
        finance: true,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
      },
      action: null,
    },
    {
      title: {
        fr: 'Prendre 15 minutes pour écouter votre moitié',
        en: 'Take 15 minutes to listen your other half',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
      },
      action: null,
    },
    {
      title: {
        fr: 'Préparer un café pour votre partenaire',
        en: 'Prepare the coffee for your partner',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
      },
      action: null,
    },
    {
      title: {
        fr: "Presser un jus d'orange frais pour votre partenaire",
        en: 'Press a fresh orange juice for your partner',
      },
      budget: 1,
      time: 0,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
      },
      action: null,
    },
    {
      title: {
        fr: 'Cuisiner un repas gastronomique',
        en: 'Cook a gourmet meal',
      },
      budget: 1,
      time: 2,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '11 idées recettes',
            type: 'link',
            url: 'https://www.elle.fr/Elle-a-Table/Les-dossiers-de-la-redaction/Dossier-de-la-redac/Menu-gastronomique',
          },
          en: {
            title: '16 recipes ideas',
            type: 'link',
            url: 'https://www.google.com/search?q=cook+gourmet+meal+recipes+at+home&sca_esv=84e39f926da01c5e&sxsrf=ADLYWIKPyBXgIMofALGX9l9XjAb_Vehgog%3A1722762739232&ei=80WvZt_YDfm3i-gP0NSG2AM&ved=0ahUKEwifnuqo_9qHAxX52wIHHVCqATsQ4dUDCBA&uact=5&oq=cook+gourmet+meal+recipes+at+home&gs_lp=Egxnd3Mtd2l6LXNlcnAiIWNvb2sgZ291cm1ldCBtZWFsIHJlY2lwZXMgYXQgaG9tZTIFECEYoAEyBRAhGKABSIkIUE1YpAdwAXgBkAEAmAF4oAHaBKoBAzQuMrgBA8gBAPgBAZgCB6AC9ATCAgoQABiwAxjWBBhHwgIFECEYnwXCAgQQIRgVmAMAiAYBkAYIkgcDNC4zoAeKIA&sclient=gws-wiz-serp',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Organiser un picnic',
        en: 'Organize a picnic',
      },
      budget: 1,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Regarder le film préféré de votre partenaire',
        en: "Watch your partner's favorite movie",
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Laisser votre partenaire choisir un film',
        en: "Let your partner's choose a movie",
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Regarder un épisode de la série préférée de votre partenaire',
        en: "Watch one episode your partner's favorite serie",
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Laisser votre partenaire choisir un épisode de série à regarder ensemble',
        en: "Let your partner's choose an episode of a serie",
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Prendre un bain ensemble',
        en: 'Take a bath together',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: true,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Prendre une douche ensemble',
        en: 'Take a shower together',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Jouer à un jeu de société ensemble',
        en: 'Play a board game together',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Jouer à un jeu vidéo ensemble',
        en: 'Play a video game together',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Créer un album photo',
        en: 'Create a photo album',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire une photo souvenir',
        en: 'Make a memory photo',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: false,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Allez courir ensemble',
        en: 'Go run together',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Visiter un musée ensemble',
        en: 'Visit a museum',
      },
      budget: 1,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Visiter une galerie d'art ensemble",
        en: 'Visit an art gallery together',
      },
      budget: 1,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller à un concert',
        en: 'Go to a live concert',
      },
      budget: 2,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Aller à un spectacle d'humour",
        en: 'Go to a comedy show',
      },
      budget: 2,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller à la plage',
        en: 'Go to the beach',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller dans un SPA',
        en: 'Go to a SPA',
      },
      budget: 3,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: true,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser un massage de couple',
        en: 'Organize a couple massage',
      },
      budget: 3,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller au cinéma',
        en: 'Go to the cinema',
      },
      budget: 2,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire un tour à vélo',
        en: 'Make a velo ride together',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller à un cours de danse',
        en: 'Go to a dance lesson',
      },
      budget: 2,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire une randonnée',
        en: 'Hiking together',
      },
      budget: 0,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Visiter un zoo',
        en: 'Visit a zoo',
      },
      budget: 1,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire un compliment',
        en: 'Make a compliment',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Dire "Je t\'aime"',
        en: 'Say "I Love you"',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller au fitness ensemble',
        en: 'Go together to the fitness',
      },
      budget: 2,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser un cours de cuisine',
        en: 'Organize un cours de cuisine',
      },
      budget: 3,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Pratiquer une position sexuelle différente',
        en: 'Try a new sexual position',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: true,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: '11 positions sexuelles à essayer',
            type: 'link',
            url: 'https://www.salutbonjour.ca/2020/01/30/les-11-positions-du-kamasutra-a-essayer',
          },
          en: {
            title: '56 fun positions to try',
            type: 'link',
            url: 'https://www.menshealth.com/sex-women/a19547362/45-sex-positions-guys-should-know/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faire du yoga ensemble',
        en: 'Do yoga together',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Yoga gratuit en ligne',
            type: 'link',
            url: 'https://mon.yoga/blog/cours-de-hatha-yoga-en-ligne-gratuits-en-francais/',
          },
          en: {
            title: 'Free online yoga',
            type: 'link',
            url: 'https://yogawithadriene.com/',
          },
        },
      ],
    },
    {
      title: {
        fr: "Faire de l'acroyoga ensemble",
        en: 'Do acro-yoga together',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: "Comment faire de l'acroyoga",
            type: 'link',
            url: 'http://acroyogaworld.fr/5-poses-acroyoga-facile-pour-debuter',
          },
          en: {
            title: 'How to do acroyoga',
            type: 'link',
            url: 'https://www.yogaemotion.net/acroyoga-for-beginners',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Mettre de la musique et danser',
        en: 'Put music and dance',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: true,
        number: false,
        partner_pleasure: true,
        my_pleasure: true,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Prendre 15 minutes et écouter totalement l'autre",
        en: 'Take 15 minutes and listen totally',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller à la plage',
        en: 'Go to the beach',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: true,
        service: true,
        moment: false,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller camper en forêt',
        en: 'Go camp in the forest',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Regarder le coucher de soleil ensemble',
        en: 'Look the sunset together',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire un concours de cuisine',
        en: 'Do a cooking competition',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Partir dehors en exploration',
        en: 'Go chill outside',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller faire du shopping',
        en: 'Go make shopping',
      },
      budget: 3,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: false,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Poser 3 questions coquines',
        en: 'Ask 3 naughty questions',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: false,
        energy: true,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Enseigner quelque chose à son partenaire',
        en: 'Teach something to my partner',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Apprendre quelque chose de son partenaire',
        en: 'Learn something to my partner',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire une blague marrante',
        en: 'Make a funny joke',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser un karaoké',
        en: 'Organize a karaoke',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Choisir un livre à lire à 2',
        en: 'Choose a book to read together',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire un concours de dessin',
        en: 'Do a painting competition',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser une chasse au trésor',
        en: 'Organize a treasure hunt',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment organiser une chasse au trésor',
            type: 'link',
            url: 'https://lesminimondes.fr/blog/activites-famille/chasse-au-tresor/',
          },
          en: {
            title: 'How to plan a fun tresaure hunt',
            type: 'link',
            url: 'https://www.instructables.com/HOW-TO-PLAN-A-FUN-TREASURE-HUNT/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faire un SPA à la maison',
        en: 'Do a SPA at home',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: true,
        service: true,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Un SPA à la maison en 5 étapes',
            type: 'link',
            url: 'https://ch.loccitane.com/fr-ch/une-journee-relaxante-a-la-maison',
          },
          en: {
            title: '10 tips for a prefect DIY SPA at home',
            type: 'link',
            url: 'https://www.aarp.org/entertainment/style-trends/info-2018/diy-spa-night.html',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Méditer ensemble',
        en: 'Meditate together',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: true,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Partir en balade',
        en: 'Go walk together',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Jouer au bowling',
        en: 'Play bowling',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire du roller',
        en: 'Do rollerblade',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller bronzer ensemble',
        en: 'Go sunbathing together',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Préparer un cocktail',
        en: 'Prepare a cocktail',
      },
      budget: 1,
      time: 1,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Recettes de cocktails',
            type: 'link',
            url: 'https://www.elle.fr/Elle-a-Table/Fiches-cuisine/Tous-les-themes/Recettes-de-cocktail',
          },
          en: {
            title: '84 easy cocktails recipes',
            type: 'link',
            url: 'https://www.bbcgoodfood.com/recipes/collection/easy-cocktail-recipes',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Aller au marché',
        en: 'Go to the morning market',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller boire un café',
        en: 'Go drink a coffee',
      },
      budget: 2,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire un café',
        en: 'Make a coffee',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Jouer à action ou vérité',
        en: 'Play to action or truth',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire un pari drôle',
        en: 'Make a funny bet',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: true,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Organiser une dégustation à l'aveugle",
        en: 'Organize a blind tasting',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Se dessiner mutuellement un tatouage éphémère sur la peau',
        en: 'Draw each other a temporary tattoo on the skin',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser une soirée déguisée',
        en: 'Organize a fance dress party',
      },
      budget: 2,
      time: 4,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Aller à une soirée 90's",
        en: "Go to a 90's party",
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller à une soirée techno / electro',
        en: 'Go to a techno / electro party',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Jardiner ensemble',
        en: 'Gardening together',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Regarder les étoiles',
        en: 'Look at the star',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser un barbecue à la maison',
        en: 'Organize a BBQ at home',
      },
      budget: 2,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser une soirée pyjama',
        en: 'Organize a pyjama party',
      },
      budget: 0,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: true,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire du fengshui',
        en: 'Do some fengshui',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: false,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Le guide du Feng Shui',
            type: 'link',
            url: 'https://www.couleur-chanvre.com/blog/feng-shui-maison/',
          },
          en: {
            title: 'A guide to a Feng Shui house',
            type: 'link',
            url: 'https://www.realsimple.com/home-organizing/decorating/feng-shui-house',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faire du fengshui',
        en: 'Do some fengshui',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: false,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Le guide du Feng Shui',
            type: 'link',
            url: 'https://www.couleur-chanvre.com/blog/feng-shui-maison/',
          },
          en: {
            title: 'A guide to a Feng Shui house',
            type: 'link',
            url: 'https://www.realsimple.com/home-organizing/decorating/feng-shui-house',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faire des origamis',
        en: 'Do some origamis together',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment faire des origamis faciles',
            type: 'link',
            url: 'https://kamidine.com/blogs/blog-papier-origami/comment-s-occuper-pendant-les-vacances-5-idees-d-origami-faciles-a-faire-avec-vos-enfants-sur-le-theme-des-animaux',
          },
          en: {
            title: 'How to make easy origami',
            type: 'link',
            url: 'https://origami.guide/instructions/easy-origami/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Manger au lit des chips',
        en: 'Eat some chips in bed',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Raconter un secret',
        en: 'Tell a secret',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: true,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Préparer une petite attention',
        en: 'Prepare a small gift',
      },
      budget: 1,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "S'habiller de la même façon",
        en: 'Dress the same',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser une soirée sans écran',
        en: 'Organize an evening without screen',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Offrir des fleurs',
        en: 'Offer flowers',
      },
      budget: 2,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Offrir un bijou',
        en: 'Offer a piece of jewelry',
      },
      budget: 4,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Aller sur l'eau",
        en: 'Go on water',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser un atelier pâtisserie',
        en: 'Organize a pastry workshop',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Recettes de pâtisseries faciles',
            type: 'link',
            url: 'https://www.elle.fr/Elle-a-Table/Fiches-cuisine/Tous-les-themes/Recettes-de-patisseries',
          },
          en: {
            title: '35 easy patry recipes you can make at home',
            type: 'link',
            url: 'https://www.tasteofhome.com/collection/easy-pastry-recipes-you-can-make-at-home/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Aller manger une glace',
        en: 'Go eat an ice',
      },
      budget: 1,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller à la patinoire',
        en: 'Go ice skating',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Cuisiner une pizza ensemble',
        en: 'Cook a pizza together',
      },
      budget: 1,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Jouer au ping-pong',
        en: 'Play ping-pong together',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller aux bains thermaux',
        en: 'Go to thermal bath',
      },
      budget: 4,
      time: 4,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: true,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Reparler de vieux souvenirs positifs',
        en: 'Talking about old positive memories',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller faire un bain de minuit',
        en: 'Go make a midnight bath',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser un premier date comme si vous ne vous connaissiez pas',
        en: "Organize a first date like you don't know each other",
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Lire le livre sur les 5 languages de l'amour de Gary Chapman",
        en: 'Read the books on the 5 love of languages by Gary Chapman',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: false,
        eros: false,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: true,
        number: false,
        partner_pleasure: false,
        my_pleasure: true,
        duree: false,
        formation: true,
      },
      actions: [
        {
          fr: {
            title: "Les cinq langages de l'amour en résumé",
            type: 'link',
            url: 'https://enduelouenduo.com/les-5-langages-de-lamour-resume/',
          },
          en: {
            title: 'Discover the books',
            type: 'link',
            url: 'https://5lovelanguages.com/',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Lire le livre sur les femmes viennent de vénus, les hommes de mars de John Gray',
        en: 'Read the books on women come from Venus, men from Mars by John Gray',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: false,
        eros: false,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: true,
        number: false,
        partner_pleasure: false,
        my_pleasure: true,
        duree: false,
        formation: true,
      },
      actions: [
        {
          fr: {
            title: 'Le livre',
            type: 'link',
            url: 'https://www.amazon.fr/hommes-viennent-femmes-Mod%C3%A8le-al%C3%A9atoire/dp/2290029122',
          },
          en: {
            title: 'The book',
            type: 'link',
            url: 'https://www.amazon.com/Men-Mars-Women-Venus-Understanding/dp/0060574216',
          },
        },
      ],
    },
    {
      title: {
        fr: "Lire le livre sur l'intelligence érotique d'Esther Perel",
        en: 'Read the book on Mating in Captivity : Unlocking Erotic Intelligence by Esther Perel',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: false,
        eros: false,
        ludus: false,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: true,
        number: false,
        partner_pleasure: false,
        my_pleasure: true,
        duree: false,
        formation: true,
      },
      actions: [
        {
          fr: {
            title: 'Le livre',
            type: 'link',
            url: 'https://www.amazon.fr/Lintelligence-%C3%A9rotique-Esther-PEREL/dp/2266181742?crid=239I0OOQJBYXO&keywords=l%27intelligence+%C3%A9rotique+esther+perel&qid=1703068621&sprefix=l%27intelligence+%C3%A9rotique,aps,73&sr=8-1&linkCode=sl1&tag=zenlove0f-21&linkId=34881466406d94909d2c6bb2ce7905b0&language=fr_FR&ref_=as_li_ss_tl',
          },
          en: {
            title: 'The book',
            type: 'link',
            url: 'https://www.amazon.com/Mating-Captivity-Unlocking-Erotic-Intelligence-ebook/dp/B0071M88DQ',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faire la vaisselle',
        en: 'Do the dishes',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: false,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire la lessive',
        en: 'Do the laundry',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: false,
        eros: false,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Dire ce que vous aimez chez l'autre",
        en: 'Tell what you like',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Dire la qualité que vous préférez à l'autre",
        en: 'Tell the quality that you prefer to your partner',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Dire à votre partenaire comme il est précieux à vos yeux',
        en: 'Tell to your half, how she is or he is precious',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Dites à votre partenaire comme vous l'admirez et êtes fière ",
        en: "Tell to your half, how you're proud of him, her",
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Dites à votre partenaire que vous le trouvez beau / belle',
        en: 'Tell to your partner that you find him / her really attractive',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller checher des croissants frais à la boulangerie',
        en: 'Go get fresh croissants from the bakery',
      },
      budget: 2,
      time: 1,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: true,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Construisez une cabane dans votre salon',
        en: 'Build a hut in your living room',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faites une bataille de coussin',
        en: 'Have a pillow fight',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Rejouer le moment de la première rencontre',
        en: 'Replay the moment of first meeting',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Organiser un blind test de musique',
        en: 'Organize a music blind test',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller à une dégustation',
        en: 'Go to a tasting',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faites une activité insolite dans la région',
        en: 'Do an unusual activity in the region',
      },
      budget: 3,
      time: 4,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Cacher un petit cadeau dans les poches de votre partenaire',
        en: 'Hide a little gift in the pocket of your partner',
      },
      budget: 1,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: true,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Envoyer un texto coquin à votre partenaire',
        en: 'Send a sexy texto to your partner',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Envoyer un texto coquin à votre partenaire',
        en: 'Send a sexy texto to your partner',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: false,
        word: true,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Jouer à un jeu de société ensemble',
        en: 'Play a board game together',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Commend',
        en: 'Order food',
      },
      budget: 2,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faire un concours de sudoku',
        en: 'Make a sudoku competition',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: false,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Goûter ensemble quelque chose que vous n'avez jamais essayé",
        en: 'Taste something together that you never tried',
      },
      budget: 1,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller vous promener ensemble sans but',
        en: 'Go walk together without goal',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Faites l'amour dans un endroit insolite",
        en: 'Make love in an unusual place',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: true,
        partner_pleasure: true,
        my_pleasure: true,
        duree: true,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faites un câlin non sexuel à votre partenaire',
        en: 'Give your partner a non-sexual hug',
      },
      budget: 0,
      time: 0,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: true,
        number: false,
        partner_pleasure: true,
        my_pleasure: true,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faites une commande surprise en ligne pour votre partenaire',
        en: 'Make a suprise order for your partner online',
      },
      budget: 2,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Demander à votre partenaire sa plus grosse peur',
        en: "Ask your partner what it's biggest fear",
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: true,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Demander à votre partenaire quels sont ses rêves',
        en: 'Ask your partner what are his or her dreams',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: true,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Regarder votre partenaire dans les yeux pendant 1 minutes sans rire',
        en: 'Look your partner in the eye more that one minute without laugh',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faites une bataille de pouce',
        en: 'Have a thumb fight',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Buvez un verre au jardin ou sur votre balcon',
        en: 'Drink something together on your balcony or in the garden',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: true,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Parlez de vos vacances de rêves',
        en: 'Talk abour your dream holiday',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: true,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Parlez de vos désirs futurs ensemble',
        en: 'Talk about your future plans together',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: true,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faites un concours de sculpture dans une pomme',
        en: 'Make an apple sculpture competition',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Louer un vélo deux places (tandems)',
        en: 'Rent a two places bike',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Inventer un jeu ensemble',
        en: 'Invent a game together',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faites un massage des pieds à votre partenaire',
        en: 'Make a foot massage to your partner',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: true,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment faire un bon massage des pieds',
            type: 'link',
            url: 'https://fr.wikihow.com/faire-un-massage-du-pied',
          },
          en: {
            title: 'How to make a good foot massage',
            type: 'link',
            url: 'https://www.wikihow.com/Give-a-Foot-Massage',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faites un massage des mains à votre partenaire',
        en: 'Make a hand massage to your partner',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: true,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment faire un massage des mains',
            type: 'link',
            url: 'https://www.secretsdemiel.com/blog/reflexologie-auto-massage-des-mains-a-realiser-soi-meme-detendre-et-rebooster-le-corps/',
          },
          en: {
            title: 'How to make a good hand massage',
            type: 'link',
            url: 'https://www.wikihow.com/Massage-Someone%27s-Hand',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Faites un massage de la tête à votre partenaire',
        en: 'Make a head massage to your partner',
      },
      budget: 0,
      time: 1,
      categories: {
        touch: true,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: true,
        frustration: false,
        number: true,
        partner_pleasure: true,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: [
        {
          fr: {
            title: 'Comment faire un massage de la tête',
            type: 'link',
            url: 'https://www.nivea.ch/fr-ch/conseils/idees-pratiques/massage-de-la-tete-programme-bien-etre-explique',
          },
          en: {
            title: 'How to make a good head massage',
            type: 'link',
            url: 'https://www.wikihow.com/Give-a-Head-Massage',
          },
        },
      ],
    },
    {
      title: {
        fr: 'Aller à un bel endroit',
        en: 'Go to a beautiful place',
      },
      budget: 0,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: true,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Faites une activité interdite ensemble',
        en: 'Do a forbidden activity together',
      },
      budget: 0,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: "Aller dans un parc d'attraction",
        en: 'Go to an amusement park',
      },
      budget: 4,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: false,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Laisser votre partenaire choisir une activité de son choix',
        en: 'Let your partner choose an activity of his / her choice',
      },
      budget: 2,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Accompgner votre partenaire pour faire sa passion',
        en: 'Go with your partner to make his / her passion',
      },
      budget: 1,
      time: 3,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: false,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: false,
        decision: true,
        task: false,
        relation: true,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Donner carte blanche à votre partenaire pour un achat plaisir',
        en: 'Give a white card to your partner for a pleasure buy',
      },
      budget: 3,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: true,
        decision: true,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Aller chercher votre partenaire à son travail',
        en: 'Go pick your partner to the work',
      },
      budget: 0,
      time: 2,
      categories: {
        touch: false,
        service: false,
        moment: true,
        gift: true,
        word: false,
        stress: true,
        energy: true,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: false,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Donner carte blanche à votre partenaire pour faire un changement chez vous',
        en: 'Give a white card to your partner to change something on you',
      },
      budget: 3,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: true,
        storge: true,
        finance: true,
        decision: true,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
    {
      title: {
        fr: 'Offrir le coiffeur à votre partenaire',
        en: 'Offer the hairdresser to your partner',
      },
      budget: 3,
      time: 0,
      categories: {
        touch: false,
        service: false,
        moment: false,
        gift: true,
        word: false,
        stress: false,
        energy: false,
        connection: true,
        eros: true,
        ludus: false,
        storge: true,
        finance: true,
        decision: false,
        task: false,
        relation: false,
        envie: false,
        frustration: false,
        number: false,
        partner_pleasure: false,
        my_pleasure: false,
        duree: false,
        formation: false,
      },
      actions: null,
    },
  ];
}
