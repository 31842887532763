import { Component, OnInit, Input, ViewChild, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
})
export class DividerComponent implements OnInit {
  public classP: string = 'normalP';
  @ViewChild('dividerLine') dividerLine;

  public _color: string = '';
  @Input() public width: number = 200;
  @Input() public height: number = 1;
  @Input() public notation: string = 'px';

  @Input() set color(value: string) {
    this._color = value;

    if (this.dividerLine == null) {
      return;
    }
    let styles = getComputedStyle(document.documentElement);
    let val = String(
      styles.getPropertyValue('--ion-color-' + this._color)
    ).trim();
    this.renderer.setStyle(this.dividerLine.nativeElement, 'fill', val);
  }
  constructor(private renderer: Renderer2) {}

  ngOnInit() {}
  ngAfterViewInit() {
    this.renderer.setStyle(
      this.dividerLine.nativeElement,
      'width',
      this.width + this.notation
    );
    //this.renderer.setStyle(this.dividerLine.nativeElement, 'border-bottom', this.height + 'px solid var(--ion-color-primary)');
    if (this._color != '') {
      let styles = getComputedStyle(document.documentElement);
      let value = String(
        styles.getPropertyValue('--ion-color-' + this._color)
      ).trim();
      this.renderer.setStyle(this.dividerLine.nativeElement, 'fill', value);
    }
    /*  if (this._color.length > 0)
      {
      }*/
  }
}
