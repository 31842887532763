<ion-content>
  <ion-fab vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button color="white" size="small" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="container">
    <div class="middle">
      <h1 class="title-modal">{{'contact.contact' | translate}}</h1>
      <app-divider></app-divider>
      <form [formGroup]="validations_form_email">
        <div
          *ngIf="validations_form_email.controls['email'].touched && !validations_form_email.controls['email'].valid"
          class="allWidth flex-col-center"
        >
          <ion-icon class="ion-margin-end" name="warning-outline"></ion-icon>
          <span>{{'login.problemEmail'}}</span>
        </div>

        <ion-item lines="none" class="transparent" class="ion-margin-top">
          <ion-icon color="primary" slot="start" name="mail"></ion-icon>
          <ion-input
            required
            formControlName="email"
            inputmode="email"
            type="email"
            placeholder="{{'login.yourEmail' | translate}}"
            autocomplete="on"
            required
          ></ion-input>
        </ion-item>

        <div
          *ngIf="validations_form_email.controls['text'].touched && !validations_form_email.controls['text'].valid"
          class="allWidth flex-col-center"
        >
          <ion-icon class="ion-margin-end" name="warning-outline"></ion-icon>
          <span>{{'contact.problemText'}}</span>
        </div>

        <ion-item lines="none" class="transparent" class="ion-margin-top">
          <ion-textarea
            autoGrow="true"
            rows="10"
            required
            formControlName="text"
            placeholder="{{'contact.yourMessage' | translate}}"
          ></ion-textarea>
        </ion-item>

        <div
          class="allWidth ion-text-center ion-margin-top ion-margin-bottom ion-padding-start ion-padding-end"
        >
          <ion-button color="primary" shape="round" (click)="send()">
            <ion-icon name="send" slot="end"></ion-icon>
            {{'contact.send'|translate}}
          </ion-button>
        </div>
      </form>
    </div>
  </div>
</ion-content>
