import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import { first } from 'rxjs/operators';
import { ServicesService } from './services.service';
import { DateTime } from 'luxon';
import { Debug } from '@app/class/debug';
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public language: string = 'fr';
  public lifetime: number = 0;
  public ready: boolean = false;
  public unique_identifier: string = '';
  public ios_free: boolean = false;
  public currency: string = 'usd';
  constructor(
    private translateService: TranslateService,
    private storageService: StorageService,
    private services: ServicesService
  ) {
    this.free_trial = {
      date_finish: DateTime.now()
        .plus({
          day: 1,
        })
        .toISO(),
      date_staying: 1,
    };
    this.is_premium = true;
  }

  start() {
    this.unique_identifier_generation();
    console.log('ID:' + this.unique_identifier);
    this.init();
    this.ready = true;
  }

  unique_identifier_generation() {
    if (this.storageService.check('unique_identifier')) {
      this.unique_identifier = this.storageService.get('unique_identifier');
    } else {
      this.unique_identifier = this.services.utilsService.generateUUID();
      this.storageService.set('unique_identifier', this.unique_identifier);
    }

    return this.unique_identifier;
  }

  init() {
    if (
      (Debug.ios_publication_mode && this.services.utilsService.isNativeIOS) ||
      Debug.disable_premium
    ) {
      this.ios_free = true;
      this.is_premium = true;
    }

    let api_token = this.storageService.get('api_token', null);

    if (this.storageService.check('temp_user')) {
      this.checkFreeTrial(this.storageService.get('temp_user'));
    }

    if (api_token != null) {
      this.user = this.storageService.get('user', null);
      this.checkBuy();
      this.services.apiService.setToken(api_token);
      this.getUser();
    } else {
      this.logGuest();
    }
  }

  askForReview(user) {
    if (
      this.services.storageService.check('review_give') ||
      !this.checkIfDataFilled()
    ) {
      return;
    }

    let last_date = this.services.storageService.get('review_date', null);
    let show = false;
    if (last_date == null && user.day_of_connection > 3) {
      show = true;
    }

    if (
      last_date != null &&
      this.services.dateService.getDayDifferenceFromToday(last_date) > 15
    ) {
      show = true;
    }

    if (show) {
      let date = DateTime.now();
      this.services.storageService.set('review_date', date.toISO());
      this.services.modalService.openReview();
    }
  }

  logGuest() {
    let utm_source = null;
    if (this.services.menuService.getParams['utm_source'] != null) {
      utm_source = this.services.menuService.getParams['utm_source'];
    }
    if (utm_source == null && this.services.utilsService.isNativeAndroid) {
      utm_source = 'AndroidApp';
    }
    if (utm_source == null && this.services.utilsService.isNativeIOS) {
      utm_source = 'IOSApp';
    }

    if (utm_source == null) {
      utm_source = document.referrer;
    }

    let data = JSON.stringify({
      timezone: this.services.utilsService.getTimezone(),
      language: this.language,
      unique_identifier: this.services.sessionService.unique_identifier,
      utm_source: utm_source,
      //recaptchaToken: this.recaptchaToken
    });

    this.services.apiService
      .post('auth/guest?ngsw-bypass=true', data)
      .subscribe({
        next: (data: any) => {
          if (data.status_code == 200) {
            this.storageService.set('temp_user', data.user);
            this.checkFreeTrial(data.user);

            let is_new = this.storageService.get('new', true);
            if (!this.checkIfDataFilled()) {
              this.services.menuService.navigate('welcome');
            }

            if (Debug.generate_new_data) {
              this.services.dataService.generateTestData();
            }

            setTimeout(() => {
              this.askForReview(data.user);
            }, 10000);

            this.currency = data.user.currency;

            this.storageService.set('new', false);
          } else {
          }
        },
        error: (e) => {
          console.error(e);
        },
        complete: () => {},
      });
  }

  public checkIfDataFilled() {
    if (this.services.dataService.data == null) {
      return false;
    }

    if (this.services.dataService.data['love_attitudes'] != null) {
      return true;
    }

    if (this.services.dataService.data['role_repartition'] != null) {
      return true;
    }

    if (this.services.dataService.data['love_languages'] != null) {
      return true;
    }
    if (this.services.dataService.data['mini_test_lovelanguage'] != null) {
      return true;
    }

    if (
      this.services.dataService.data['suivi'] != null &&
      this.services.dataService.data['suivi'].length > 0
    ) {
      return true;
    }

    return false;
  }

  public user: any = null;
  public is_premium: boolean = false;

  public has_buyed: boolean = false;
  free_trial_day: number = 7;
  public free_trial: any = null;

  getUser() {
    this.services.apiService.get('auth/user?ngsw-bypass=true').subscribe({
      next: (data: any) => {
        if (data.status_code == 200) {
          this.user = data.user;
          this.storageService.set('user', this.user);
          this.storageService.set('temp_user', data.user);

          this.checkFreeTrial(this.user);
          this.checkBuy();

          console.log('USER');
          console.log(this.user);
          this.currency = this.user.currency;

          this.setLanguage(this.user.language);

          setTimeout(() => {
            this.askForReview(data.user);
          }, 10000);

          if (this.user.saves.length > 0) {
            let save = this.user.saves[0];
            if (
              this.services.dataService.data == null ||
              this.services.dataService.data.count == null ||
              this.services.dataService.data.count < save.count
            ) {
              this.services.dataService.data = save.save;
              this.services.dataService.saveData(true);
              this.services.eventService.publish('refresh_graphs');
            }
          }
        } else {
        }
      },
      error: (e) => {
        console.error(e);
      },
      complete: () => {},
    });
  }

  updateLanguage() {
    let data = JSON.stringify({
      language: this.language,
      unique_identifier: this.unique_identifier,
    });

    this.services.apiService.put('user/language', data).subscribe({
      next: (data: any) => {
        if (data.status_code == 200) {
          console.log('Language UPDATED');
        } else {
        }
      },
      error: (e) => {
        console.error(e);
      },
      complete: () => {},
    });
  }

  updateUser() {
    if (this.user == null) {
      return;
    }
    let data = JSON.stringify({
      cloud_save: this.user.cloud_save,
      language: this.user.language,
    });

    this.services.apiService.put('user/' + this.user.id, data).subscribe({
      next: (data: any) => {
        if (data.status_code == 200) {
          console.log('USER UPDATED');
        } else {
        }
      },
      error: (e) => {
        console.error(e);
      },
      complete: () => {},
    });
  }

  checkBuy() {
    if (this.user.buyed.subscription != null || this.user.buyed.lifetime) {
      this.is_premium = true;
      this.has_buyed = true;
    }
  }

  public created_at: string = null;
  checkFreeTrial(user: any) {
    this.storageService.set('user_created_at', user.created_at);

    this.created_at = user.created_at;
    let date_creation = DateTime.fromISO(user.created_at);

    this.lifetime = Math.abs(
      this.services.dateService.getDayDifferenceFromToday(date_creation)
    );

    let trial_staying = 1;
    if (user.trial != null) {
      trial_staying = user.trial;
    }

    if (!this.storageService.check('day_trial')) {
      this.storageService.set('day_trial', trial_staying);
    }

    let bonus = 0;

    if (this.storageService.check('bonus_trial')) {
      bonus = this.storageService.get('bonus_trial', 0);
    }

    trial_staying += bonus;
    if (
      trial_staying > 0 &&
      !this.services.utilsService.getDebug('disable_trial')
    ) {
      this.free_trial = {
        date_finish: date_creation
          .plus({
            day: trial_staying,
          })
          .toISO(),
        date_staying: trial_staying,
      };

      this.is_premium = true;
    } else {
      this.free_trial = null;
      if (!this.ios_free) this.is_premium = false;
    }

    if (Debug.locked) {
      this.free_trial = null;
      this.is_premium = false;
      return;
    }
  }

  setLanguage(lang: string) {
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    this.language = lang;

    this.storageService.set('language', lang);

    if (this.user != null && this.user.language != this.language) {
      this.user.language = this.language;
    }
    this.updateLanguage();
  }

  disconnect() {
    this.user = null;
    this.is_premium = false;
    this.has_buyed = false;
    this.services.storageService.delete('api_token');
    this.services.storageService.delete('created_at');
    this.services.storageService.delete('unique_identifier');
    this.services.storageService.delete('user');
    this.services.sessionService.logGuest();
  }

  isReady(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.ready) {
        resolve(true);
      } else {
        let interval = setInterval(() => {
          if (this.ready) {
            clearInterval(interval);
            resolve(true);
          }
        }, 200);
      }
    });
  }
}
