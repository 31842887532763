import { Injectable, output } from '@angular/core';
import { Statistics } from 'statistics.js';
import { ServicesService } from './services.service';
import { StorageService } from './storage.service';
import { first } from 'rxjs/operators';
import { Observable, Observer } from 'rxjs';
import { DateTime } from 'luxon';
import { RandomForestRegression } from 'ml-random-forest';
import { Data } from '@app/class/data';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public data: any;
  public ready: boolean = false;
  public empty_data: any = {
    suivi: [],
    my_missions: [],
    history_missions: [],
    ai_internal_data: [],
    malus_missions: [],
    utc_datetime_generated_mission: null,
    stats: null,
  };
  constructor(
    private services: ServicesService,
    private storageService: StorageService
  ) {
    this.storageService
      .isReady()
      .pipe(first())
      .subscribe((data) => {
        this.loadData();

        this.ready = true;
      });
  }

  isReady(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.ready) {
        resolve(true);
      } else {
        let interval = setInterval(() => {
          if (this.ready) {
            clearInterval(interval);
            resolve(true);
          }
        }, 200);
      }
    });
  }

  private loadData() {
    this.data = this.services.storageService.get('save');
    if (this.data == null) {
      this.data = { ...this.empty_data };
    }

    this.stats = this.getKey('stats', null);
  }

  public saveData(disable_cloud_saving: boolean = false) {
    if (this.data.count == null) {
      this.data.count = 0;
    }
    this.data.count++;
    this.services.storageService.set('save', this.data);

    if (!disable_cloud_saving) {
      if (
        this.services.sessionService.user != null &&
        this.services.sessionService.user.cloud_save
      ) {
        let data = JSON.stringify({
          save: this.data,
          count: this.data.count,
        });
        this.services.apiService.post('save', data).subscribe({
          next: (data: any) => {
            if (data.status_code == 200) {
              console.log('SAVED');
            } else {
            }
          },
          error: (e) => {
            console.error(e);
          },
          complete: () => {},
        });
      }
    }
  }

  public getKey(key: string, defaut: any = []) {
    if (this.data == null) {
      this.data = { ...this.empty_data };
    }

    let _data = this.data[key];
    if (_data == null) {
      _data = defaut;
    }

    return _data;
  }

  public setKey(key: string, data: any) {
    this.data[key] = data;
    this.saveData();
  }

  public eraseData() {
    this.data = { ...this.empty_data };
    this.stats = null;
    this.data.count = 0;
    this.services.storageService.delete('save');
    this.saveData(true);
  }

  public initKey(key: string, defaut: any = []) {
    if (this.data == null) {
      this.data = { ...this.empty_data };
    }

    if (this.data[key] == null) {
      this.data[key] = defaut;
    }
  }

  stats: any = {};

  /* 

  Correlation : 

    High Degree: Values between ±0.50 and ±1 suggest a strong correlation.

    Moderate Degree: Values between ±0.30 and ±0.49 indicate a moderate correlation.

    Low Degree: Values below +0.29 are considered a weak correlation.

    No Correlation: A value of zero implies no relationship.
  */

  public updateStat() {
    //construction du tableau
    this.stats = {};
    const options_randomforest = {
      seed: 3,
      maxFeatures: 2,
      replacement: false,
      nEstimators: 200,
    };

    let preparation_data = this.services.utilsService.cloneArray(
      this.services.dataService.getKey('suivi')
    );

    // SET DAY AND WEEK

    for (let item of preparation_data) {
      let date = item['utc_datetime'];

      item['week'] = 0;
      item['weekend'] = 0;
      date = DateTime.fromISO(date).toLocal();
      switch (date.weekday) {
        case 1:
          item['week'] = 1;
          break;
        case 2:
          item['week'] = 1;
          break;
        case 3:
          item['week'] = 1;
          break;
        case 4:
          item['week'] = 1;
          break;
        case 5:
          item['week'] = 1;
          break;
        case 6:
          item['weekend'] = 1;
          break;
        case 7:
          item['weekend'] = 1;
          break;
      }
    }

    let data_tab = this.services.utilsService.cloneArray(preparation_data);

    if (data_tab.length < 3) {
      console.log('Not enough data');
      return;
    }

    this.stats['entries'] = data_tab.length;

    let max_interval = 30;
    if (data_tab.length < 30) {
      max_interval = data_tab.length;
    }
    let column_name = {};
    for (let column of this.analysis_columns) {
      column_name[column.name] = 'interval';
    }

    if (max_interval > 3) {
      let count = 0;
      for (let item of data_tab) {
        for (let i = 7; i < max_interval; i++) {
          for (let column of this.analysis_columns) {
            let index = count + i;
            if (index >= data_tab.length) {
              index = index % data_tab.length;
            }
            item[column.name + '_' + i] = data_tab[index][column.name];
            column_name[column.name + '_' + i] = 'interval';
          }
        }
        count++;
      }
    } else {
      max_interval = 0;
    }

    column_name['week'] = 'interval';
    column_name['weekend'] = 'interval';

    /* console.log('DATA');
    console.log(data_tab);
    console.log('COLUMN');
    console.log(column_name);*/

    let stats_object = new Statistics(data_tab, column_name);

    for (let column of this.analysis_columns) {
      this.stats[column.name] = {};

      this.stats[column.name]['pearson'] = [];
      this.stats[column.name]['spearman'] = [];

      // Calcul des corrélations
      for (let comparison_name of column.comparison) {
        this.stats[column.name]['pearson'].push({
          key: comparison_name,
          value: stats_object.correlationCoefficient(
            comparison_name,
            column.name
          ).correlationCoefficient,
        });

        this.stats[column.name]['spearman'].push({
          key: comparison_name,
          value: stats_object.spearmansRho(comparison_name, column.name, true)
            .rho,
        });
      }

      this.sortDesc(this.stats[column.name]['pearson']);
      this.sortDesc(this.stats[column.name]['spearman']);

      this.stats[column.name]['autocorrelation'] = [];
      // Calcul des autocorrélations
      for (let i = 7; i < max_interval; i++) {
        this.stats[column.name]['autocorrelation'].push({
          key: i,
          value: stats_object.correlationCoefficient(
            column.name,
            column.name + '_' + i
          ).correlationCoefficient,
        });
      }

      this.sortDesc(this.stats[column.name]['autocorrelation']);

      // Calcul des trends

      this.stats[column.name]['trends'] = [];

      let all_suivi = this.services.dataService.getKey('suivi');
      let last = all_suivi[all_suivi.length - 1];
      let difference_from_today = Math.abs(
        this.services.dateService.getDayDifferenceFromToday(last.utc_datetime)
      );
      let data_line = this.services.utilsService
        .cloneArray(this.services.dataService.getKey('suivi'))
        .map((x) => {
          return x[column.name];
        });

      for (let i = 0; i < difference_from_today; i++) {
        data_line.push(0);
      }

      for (let i = 0; i < -data_line.length + 60; i++) {
        data_line.unshift(0);
      }

      this.stats[column.name]['average'] = this.average(data_line);

      // DEBUG TREND
      /*if (column.name == 'stress') {
        console.log('STRESS');

        console.log(data_line);
        this.stats[column.name]['trends'].push({
          key: '7',
          value: this.getTrend(data_line, 7),
        });
      }*/

      this.stats[column.name]['trends'].push({
        key: '3',
        value: this.getTrend(data_line, 3),
      });

      this.stats[column.name]['trends'].push({
        key: '7',
        value: this.getTrend(data_line, 7),
      });

      this.stats[column.name]['trends'].push({
        key: '30',
        value: this.getTrend(data_line, 30),
      });

      this.sortDesc(this.stats[column.name]['trends']);

      /* Fonctionne seulement si on enlève le fichier type dans node_modules/ml-randomforest ... */
      const regression = new RandomForestRegression(options_randomforest);

      // Random forest ML
      // Attention erreur si moins de données que de colonne
      let data_suivi = this.services.utilsService.cloneArray(preparation_data);

      if (column.random_forest) {
        let trainingSet = new Array(data_suivi.length);
        let results = new Array(data_suivi.length);
        let feature_importance = [];
        let predictionSet = new Array(data_suivi.length);
        for (let i = 0; i < data_suivi.length; i++) {
          let item = data_suivi[i];
          for (let comparison_name of column.comparison) {
            if (trainingSet[i] == null) {
              trainingSet[i] = [];
            }
            trainingSet[i].push(item[comparison_name]);

            if (i == 0) {
              feature_importance.push({
                key: comparison_name,
                value: 0,
              });
            }
          }
          predictionSet[i] = [];
          predictionSet[i].push(i);
          predictionSet[i].push(item['week']);
          predictionSet[i].push(item['weekend']);

          results[i] = item[column.name];
        }

        let predictions = [];
        if (data_suivi.length > 10) {
          regression.train(trainingSet, results);

          let importances = regression.featureImportance();

          for (let i = 0; i < importances.length; i++) {
            feature_importance[i].value = importances[i];
          }

          regression.train(predictionSet, results);

          let number_of_day = 7;
          let futures = new Array(number_of_day);
          //let next_days = new Array(number_of_day);
          for (let i = 0; i < number_of_day; i++) {
            let date = DateTime.now()
              .plus({ day: i + 1 })
              .startOf('day');

            futures[i] = [];
            futures[i].push(data_suivi.length + i);
            futures[i].push(date.weekday <= 5 ? 1 : 0);

            futures[i].push(date.weekday > 5 ? 1 : 0);
          }
          predictions = regression.predict(futures);
        }
        this.stats[column.name]['features_importance'] = feature_importance;

        this.sortDesc(this.stats[column.name]['features_importance']);

        this.stats[column.name]['predictions'] = predictions;
        this.stats[column.name]['predictions_trends'] = [];

        let data_line = results.concat(predictions);

        this.stats[column.name]['predictions_trends'].push({
          key: '3',
          value: this.getTrend(data_line, 3),
        });

        this.stats[column.name]['predictions_trends'].push({
          key: '7',
          value: this.getTrend(data_line, 7),
        });
      } else {
        this.stats[column.name]['features_importance'] = [];
        this.stats[column.name]['predictions'] = [];
        this.stats[column.name]['predictions_trends'] = [];
      }
    }

    console.log(this.stats);

    this.setKey('stats', this.stats);

    this.services.aiService.generateWeekMissions();

    this.services.eventService.publish('stats_updated', this.stats);
  }

  //https://www.investopedia.com/ask/answers/071414/whats-difference-between-moving-average-and-weighted-moving-average.asp
  getTrend(data, interval = 3) {
    if (data.length < 2) {
      return 0;
    }
    try {
      if (interval > data.length / 2) {
        interval = data.length / 2;
      }

      interval = Math.floor(interval);

      let end = data.slice(data.length - interval);
      let start = data.slice(
        data.length - interval - interval,
        data.length - interval
      );

      let average_start = start.reduce((a, b) => a + b) / start.length;
      let average_end = end.reduce((a, b) => a + b) / end.length;

      if (average_start != 0) {
        return average_end / average_start - 1;
      } else {
        return average_end;
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  simpleMovingAverage(data, interval) {
    let index = interval - 1;
    const length = data.length + 1;
    let results = [];

    while (index < length) {
      index = index + 1;
      const intervalSlice = data.slice(index - interval, index);
      const sum = intervalSlice.reduce((prev, curr) => prev + curr, 0);
      results.push(sum / interval);
    }
    return results;
  }

  average(array) {
    return array.reduce((a, b) => a + b) / array.length;
  }

  sortDesc(array) {
    return array.sort(function (a, b) {
      if (a.value > b.value) {
        return -1;
      }
      if (a.value < b.value) {
        return 1;
      }

      return 0;
    });
  }

  createSuiviObject() {
    return {
      envie: 0,
      frustration: 0,
      energy: 0,
      stress: 0,
      connection: 0,
      number: 0,
      duree: 0,
      my_pleasure: 0,
      partner_pleasure: 0,
      interval: 0,
      mission: 0,
      utc_datetime: DateTime.now().toISO(),
      utc_datetime_myself: null,
      utc_datetime_partner: null,
      utc_datetime_relation: null,
      utc_datetime_mission: null,
    };
  }

  generateTestData() {
    this.services.dataService.eraseData();
    this.services.aiService.clearData();

    console.log('!!! DATA GENERATION !!!');

    this.services.dataService.data['suivi'] = [];

    this.services.dataService.data['utc_datetime_generated_mission'] = null;

    let interval = 0;
    let partner_pleasure = this.services.utilsService.randomNumber(0, 4) / 4;
    let my_pleasure = this.services.utilsService.randomNumber(0, 4) / 4;
    let duration = this.services.utilsService.randomNumber(0, 4) / 4;

    let old_obj = {
      envie: this.services.utilsService.randomNumber(0, 4) / 4,
      frustration: this.services.utilsService.randomNumber(0, 4) / 4,
      energy: this.services.utilsService.randomNumber(0, 4) / 4,
      stress: this.services.utilsService.randomNumber(0, 4) / 4,
      connection: this.services.utilsService.randomNumber(0, 4) / 4,
      number: 0,

      duree: 0,
      my_pleasure: 0,
      partner_pleasure: 0,
      interval: 0,
    };
    for (let i = 2; i < 60; i++) {
      let date = DateTime.now().minus({ day: i });

      let random = this.services.utilsService.randomNumber(0, 5);

      let obj = {
        envie: this.falseRandom(old_obj.envie),
        frustration: this.falseRandom(old_obj.frustration),
        energy: this.falseRandom(old_obj.energy),
        stress: this.falseRandom(old_obj.stress),
        connection: this.falseRandom(old_obj.connection),
        number: random > 2 ? 1 : 0,

        duree: duration,
        my_pleasure: my_pleasure,
        partner_pleasure: partner_pleasure,
        interval: 0,
        utc_datetime: date.toISO(),
        utc_datetime_myself: date.toISO(),
        utc_datetime_partner: date.toISO(),
        utc_datetime_relation: date.toISO(),
      };

      if (random > 2) {
        duration = this.falseRandom(old_obj.duree);
        my_pleasure = this.falseRandom(old_obj.my_pleasure);

        partner_pleasure = this.falseRandom(old_obj.partner_pleasure);

        obj.duree = duration;
        obj.my_pleasure = my_pleasure;

        obj.partner_pleasure = partner_pleasure;

        obj.interval = interval;

        interval = 0;
      }

      old_obj = obj;

      this.services.dataService.data['suivi'].unshift(obj);

      interval++;
    }

    this.services.dataService.data['love_attitudes'] = {
      me: {
        primary: [
          {
            key: 'eros',
            value: 0.6285714285714286,
          },
          {
            key: 'ludus',
            value: 0.6,
          },
          {
            key: 'storge',
            value: 0.5714285714285714,
          },
        ],
        secondary: [
          {
            key: 'agape',
            value: 0.7714285714285715,
          },
          {
            key: 'pragma',
            value: 0.6285714285714286,
          },
          {
            key: 'mania',
            value: 0.6,
          },
        ],
      },
      partner: {
        primary: [
          {
            key: 'eros',
            value: 0.6571428571428571,
          },
          {
            key: 'ludus',
            value: 0.6285714285714286,
          },
          {
            key: 'storge',
            value: 0.5714285714285714,
          },
        ],
        secondary: [
          {
            key: 'agape',
            value: 0.6857142857142857,
          },
          {
            key: 'mania',
            value: 0.6571428571428571,
          },
          {
            key: 'pragma',
            value: 0.6,
          },
        ],
      },
    };

    this.services.dataService.data['role_repartition'] = {
      me: [
        {
          key: 'relation',
          value: 0.9375,
        },
        {
          key: 'decision',
          value: 0.5,
        },
        {
          key: 'finance',
          value: 0.375,
        },
        {
          key: 'task',
          value: 0.375,
        },
      ],
      partner: [
        {
          key: 'finance',
          value: 0.625,
        },
        {
          key: 'task',
          value: 0.625,
        },
        {
          key: 'decision',
          value: 0.5,
        },
        {
          key: 'relation',
          value: 0.0625,
        },
      ],
    };

    this.services.dataService.data['love_languages'] = {
      me: [
        {
          key: 'touch',
          value: 0.22857142857142856,
        },
        {
          key: 'word',
          value: 0.2,
        },
        {
          key: 'moment',
          value: 0.17142857142857143,
        },
        {
          key: 'service',
          value: 0.14285714285714285,
        },
        {
          key: 'gift',
          value: 0.11428571428571428,
        },
      ],
      partner: [
        {
          key: 'touch',
          value: 0.22857142857142856,
        },
        {
          key: 'word',
          value: 0.2,
        },
        {
          key: 'moment',
          value: 0.17142857142857143,
        },
        {
          key: 'service',
          value: 0.14285714285714285,
        },
        {
          key: 'gift',
          value: 0.11428571428571428,
        },
      ],
    };

    this.services.dataService.saveData();

    for (let i = 0; i < 10; i++) {
      let mission = this.services.utilsService.cloneObject(
        Data.missions[
          this.services.utilsService.randomNumber(0, Data.missions.length - 1)
        ]
      );
      this.services.aiService.addMalusMission(mission);
    }

    for (let i = 0; i < 30; i++) {
      let mission = this.services.utilsService.cloneObject(
        Data.missions[
          this.services.utilsService.randomNumber(0, Data.missions.length - 1)
        ]
      );

      this.services.aiService.addHistoryMission(
        mission,
        this.services.utilsService.randomNumber(3, 6),
        DateTime.now()
          .minus({ day: this.services.utilsService.randomNumber(10, 60) })
          .toISO()
      );
    }

    this.services.dataService.updateStat();

    this.services.aiService.validateMission(
      0,
      3,
      DateTime.now().minus({ day: 2 }).toISO()
    );
    this.services.aiService.validateMission(
      1,
      3,
      DateTime.now().minus({ day: 4 }).toISO()
    );

    this.saveData();

    this.services.eventService.publish('refresh_graphs');
  }

  falseRandom(oldValue) {
    let indice = 1;
    if (
      (this.services.utilsService.randomNumber(0, 1) == 1 || oldValue == 0) &&
      oldValue != 1
    ) {
      indice = 1;
    } else {
      indice = -1;
    }
    oldValue += (this.services.utilsService.randomNumber(0, 4) / 4) * indice;
    if (oldValue > 1) {
      oldValue = 1;
    }
    if (oldValue < 0) {
      oldValue = 0;
    }
    return oldValue;
  }

  analysis_columns = [
    {
      name: 'frustration',
      comparison: [
        'envie',
        'energy',
        'connection',
        'stress',
        'number',
        'week',
        'weekend',
      ],
      type: 'interval',
      random_forest: true,
    },
    {
      name: 'envie',
      comparison: [
        'energy',
        'connection',
        'stress',
        'number',
        'frustration',
        'week',
        'weekend',
      ],
      type: 'interval',
      random_forest: true,
    },
    {
      name: 'energy',
      comparison: [
        'envie',
        'connection',
        'stress',
        'number',
        'frustration',
        'week',
        'weekend',
      ],
      type: 'interval',
      random_forest: true,
    },
    {
      name: 'connection',
      comparison: [
        'envie',
        'energy',
        'stress',
        'number',
        'frustration',
        'week',
        'weekend',
      ],
      type: 'interval',
      random_forest: true,
    },
    {
      name: 'stress',
      comparison: [
        'envie',
        'energy',
        'connection',
        'number',
        'frustration',
        'week',
        'weekend',
      ],
      type: 'interval',
      random_forest: true,
    },
    {
      name: 'number',
      comparison: [
        'envie',
        'energy',
        'connection',
        'stress',
        'frustration',
        'week',
        'weekend',
      ],
      type: 'interval',
      random_forest: true,
    },
    {
      name: 'duree',
      comparison: [
        'my_pleasure',
        'partner_pleasure',
        'interval',
        'week',
        'weekend',
      ],
      type: 'interval',
      random_forest: true,
    },
    {
      name: 'my_pleasure',
      comparison: ['duree', 'partner_pleasure', 'interval', 'week', 'weekend'],
      type: 'interval',
      random_forest: true,
    },
    {
      name: 'partner_pleasure',
      comparison: ['duree', 'my_pleasure', 'interval', 'week', 'weekend'],
      type: 'interval',
      random_forest: true,
    },
    {
      name: 'interval',
      comparison: [
        'duree',
        'my_pleasure',
        'partner_pleasure',
        'week',
        'weekend',
      ],
      type: 'interval',
      random_forest: false,
    },
  ];
}
