import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  public key!: string;
  public is_used: boolean = false;
  constructor() {}

  public setKey(key: string) {
    this.key = key;
    this.is_used = true;
  }

  public getKey() {
    return this.key;
  }

  public encryptData(data: string): string {
    return CryptoJS.AES.encrypt(data, this.key).toString();
  }

  public decryptData(data: string): string {
    const bytes = CryptoJS.AES.decrypt(data, this.key);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  public generateKey(): string {
    return CryptoJS.lib.WordArray.random(128 / 8).toString();
  }
}
