import { Injectable } from '@angular/core';
import { ServicesService } from './services.service';
import { ContactPage } from '@app/modals/contact/contact.page';
import { PremiumPage } from '@app/modals/premium/premium.page';
import { FaqPage } from '@app/modals/faq/faq.page';
import { ExplicationPage } from '@app/modals/explication/explication.page';
import { PayQuestionPage } from '@app/modals/pay-question/pay-question.page';
import { ReviewPage } from '@app/modals/review/review.page';
import { NotificationPage } from '@app/modals/notification/notification.page';
import { MakingOfPage } from '@app/modals/making-of/making-of.page';
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(public services: ServicesService) {}

  public async openFaq() {
    const modal = await this.services.modalController.create({
      component: FaqPage,
      componentProps: {},
    });
    modal.present();
  }

  public async openPremium(options: any = null) {
    const modal = await this.services.modalController.create({
      component: PremiumPage,
      cssClass: ['big-modal'],
      componentProps: {
        options: options,
      },
    });
    modal.present();
  }

  public async openReview(options: any = null) {
    const modal = await this.services.modalController.create({
      component: ReviewPage,
      componentProps: {
        options: options,
      },
    });
    modal.present();
  }

  public async openNotificationAsk(options: any = null) {
    if (
      !this.services.pushNotificationService.swPushEnabled ||
      this.services.pushNotificationService.notificationIsEnabled ||
      this.services.utilsService.isNativeIOS ||
      this.services.storageService.check('notification_asked')
    ) {
      return;
    }

    const modal = await this.services.modalController.create({
      component: NotificationPage,
      componentProps: {
        options: options,
      },
    });
    modal.present();
  }

  public async openMakingOf(options: any = null) {
    const modal = await this.services.modalController.create({
      component: MakingOfPage,
      componentProps: {
        options: options,
      },
    });
    modal.present();
  }

  public async openPayQuestion(options: any = null) {
    const modal = await this.services.modalController.create({
      component: PayQuestionPage,
      componentProps: {
        options: options,
      },
    });
    modal.present();
  }

  public async openContact() {
    const modal = await this.services.modalController.create({
      component: ContactPage,
      componentProps: {},
    });
    modal.present();
  }

  public async openExplication(
    title: string,
    content: string,
    image_or_icon: string = null
  ) {
    const modal = await this.services.modalController.create({
      component: ExplicationPage,
      componentProps: {
        title: title,
        content: content,
        image_or_icon: image_or_icon,
      },
    });
    modal.present();
  }
}
