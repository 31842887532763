import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ServicesService } from '@app/services/services.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.page.html',
  styleUrls: ['./notification.page.scss'],
})
export class NotificationPage implements OnInit {
  randomImage: string = '';

  constructor(public services: ServicesService) {
    this.randomImage = this.services.utilsService.randomImagePsychology();
  }

  ngOnInit() {}

  interval: any;
  ionViewWillEnter() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null!);

    this.services.storageService.set('notification_asked', true);

    this.interval = setInterval(() => {
      if (this.services.pushNotificationService.notificationIsEnabled) {
        this._clearInterval();
        this.dismiss();
      }
    }, 1000);
  }

  _clearInterval() {
    if (this.interval != null) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  ionViewWillLeave() {
    if (window.history.state.modal) {
      window.history.back();
    }

    this._clearInterval();
  }

  activateNotification() {
    this.services.pushNotificationService.requestSubscription();
  }

  @HostListener('window:popstate', ['$event'])
  async dismiss(role: string = 'dismiss') {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    const modal = await this.services.modalController.getTop();
    if (modal) {
      this.services.modalController.dismiss(null, role);
      return;
    }
  }
}
