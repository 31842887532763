import { Injectable } from '@angular/core';
import { Platform, IonRouterOutlet } from '@ionic/angular';
import { Location } from '@angular/common';
import { NavController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import {
  ActivatedRoute,
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from '@angular/router';
import { UtilsService } from '@app/services/utils.service';
import { MenuController } from '@ionic/angular';
import { ServicesService } from '@app/services/services.service';
import { Observable, of, from, Observer } from 'rxjs';
import { Debug } from '@app/class/debug';

//declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public currentPage: string = '/';
  public currentPage_start: string = '/';
  public currentPageParent: string = '/';

  public isMobile: boolean = false;
  public menuDisplayed: boolean = true;
  public currentUrl: string = '';
  public screenSize: number = 0;
  public screenHeight: number = 0;

  public ionRouterOutlet: any = null;
  public updateIfPossible: boolean = false;
  public number_page: number = 0;
  public getParams: any = {};

  constructor(
    private platform: Platform,
    public services: ServicesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public utilsService: UtilsService,
    public modalController: ModalController
  ) {
    //https://www.sitepoint.com/get-url-parameters-with-javascript/
    this.activatedRoute.queryParams.subscribe((params) => {
      this.getParams = { ...this.getParams, ...params };

      if (this.getParams['ios'] != null) {
        this.services.utilsService.isNativeIOS = true;
        console.log('IOS');
      }

      if (this.getParams['android'] != null) {
        this.services.utilsService.isNativeAndroid = true;
        console.log('Android');
      }
    });
    this.initializeApp();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.currentPage_start = event.url;

        this.services.eventService.publish('navigationStart', {
          url: event.url,
        });
      }

      if (event instanceof NavigationEnd) {
        this.number_page++;

        this.currentPage = event.urlAfterRedirects.split('?')[0];

        let temp = this.currentPage.split('/');
        if (temp.length > 1) {
          this.currentPageParent = temp[1];
        } else {
          this.currentPageParent = '';
        }

        this.services.eventService.publish('navigationEnd', {
          url: event.url,
        });

        setTimeout(() => {
          this.services.dataLayerService.logPageView(event.url);
        }, 500);

        if (this.updateIfPossible) {
          this.updateIfPossible = false;
          window.location.reload();
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        // Present error to user
        //console.log(event.error);
      }
    });
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/blank', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  navigate(title: string, id = -1, queryParams = {}) {
    if (id != -1 && id != null) {
      this.router.navigate([title, id], { queryParams: queryParams });
    } else {
      this.router.navigate([title], { queryParams: queryParams });
    }
  }

  goBack() {
    if (this.number_page == 1) {
      this.navigate('/' + this.currentPageParent);
    } else {
      this.number_page--;
      window.history.back();
    }

    //this.location.back();
  }

  checkScreenSize() {
    if (this.platform.width() < 950) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.screenSize = this.platform.width();
    this.screenHeight = this.platform.height();
  }

  initializeApp() {
    this.checkScreenSize();

    this.platform.resize.subscribe(async () => {
      this.checkScreenSize();
    });
  }
}
