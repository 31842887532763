import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ServicesService } from '@app/services/services.service';
import { ContactPage } from '../contact/contact.page';
import { Debug } from '@app/class/debug';

@Component({
  selector: 'app-premium',
  templateUrl: './premium.page.html',
  styleUrls: ['./premium.page.scss'],
})
export class PremiumPage implements OnInit {
  constructor(public services: ServicesService) {}

  ngOnInit() {
    this.services.dataLayerService.logEvent(
      'open-premium',
      'actions',
      'open-premium',
      'open-premium'
    );
  }

  ionViewWillEnter() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null!);
    this.getPrices();
  }

  ionViewWillLeave() {
    if (window.history.state.modal) {
      window.history.back();
    }

    if (this.interval != null) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  interval: any;
  mode: string = 'buy';
  buy(product_name: string) {
    this.services.dataLayerService.logEvent(
      'begin-buy',
      'actions',
      'begin-buy',
      'begin-buy'
    );

    let add_discount = '';
    if (this.discount != null) {
      add_discount = '&discount=' + this.discount.code;
    }

    let url =
      this.services.apiService.server_url +
      `payment/pay?unique_identifier=${this.services.sessionService.unique_identifier}&product_name=${product_name}&currency=${this.current_currencie}` +
      add_discount;

    clearInterval(this.interval);

    let _count = 0;
    this.interval = setInterval(() => {
      _count++;
      if (_count > 200) {
        clearInterval(this.interval);
      }

      this.services.apiService
        .get(
          'payment/transaction?unique_identifier=' +
            this.services.sessionService.unique_identifier
        )
        .subscribe((data: any) => {
          console.log(data);
          if (data.status_code == 200) {
            this.services.dataLayerService.logEvent(
              'buyed',
              'actions',
              'buyed',
              'buyed'
            );

            if (data.status == 1) {
              clearInterval(this.interval);
              this.services.sessionService.getUser();
              this.mode = 'success';
            }
            if (data.status == 2) {
              clearInterval(this.interval);
              this.services.storageService.set('api_token', data.access_token);
              this.services.apiService.setToken(data.access_token);
              this.services.sessionService.getUser();
              this.mode = 'success';
            }
          }
        });
    }, 2000);

    this.services.browserService.openUrl(url);
  }

  price: any;
  all_price: any;
  all_currencies: any = null;
  current_currencie: string = 'usd';
  discount: any;
  getPrices() {
    this.services.apiService
      .get(
        'payment/prices?unique_identifier=' +
          this.services.sessionService.unique_identifier
      )
      .subscribe({
        next: (data: any) => {
          if (data.status_code == 200) {
            console.log('PRICE');
            console.log(data);

            this.all_price = data.price;
            this.loadCurrencies();
            this.loadPrice(this.services.sessionService.currency);

            this.discount = data.discount;
          } else {
          }
        },
        error: (e) => {
          console.error(e);
        },
        complete: () => {},
      });
  }

  loadCurrencies() {
    this.all_currencies = [];
    for (var key of Object.keys(this.all_price['conversions'])) {
      this.all_currencies.push(key);
    }
  }

  loadPrice(wanted_currencie: string = 'usd') {
    if (this.all_price['conversions'][wanted_currencie] == null) {
      console.log('CURRENCIE NOT FOUND : ' + wanted_currencie);
      wanted_currencie = 'usd';
    }
    this.current_currencie = wanted_currencie;
    this.price = this.all_price['conversions'][this.current_currencie];
  }

  async openContact() {
    const modal = await this.services.modalController.create({
      component: ContactPage,
      componentProps: {},
    });
    modal.present();
  }

  openSaleAgreement() {
    this.services.browserService.openUrl(
      this.services.utilsService.getWebsiteUrl('terms'),
      true
    );
  }

  @HostListener('window:popstate', ['$event'])
  async dismiss(role: string = 'dismiss') {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    const modal = await this.services.modalController.getTop();
    if (modal) {
      this.services.modalController.dismiss(null, role);
      return;
    }
  }
}
