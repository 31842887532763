import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  private window;

  constructor() {
    this.window = window;
  }

  private addLayer(obj) {
    if (obj && this.window.dataLayer) {
      this.window.dataLayer.push(obj);
    }
  }

  logPageView(url) {
    const hit = {
      event: 'content-view',
      pageName: url,
    };
    this.addLayer(hit);
  }

  logEvent(event, category = 'general', action, label, variables = {}) {
    const hit = {
      event: event,
      category: category,
      action: action,
      label: label,
      ...variables,
    };
    this.addLayer(hit);
  }
}
