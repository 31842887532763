import { Injectable } from '@angular/core';
import { ServicesService } from './services.service';
import { ContactPage } from '@app/modals/contact/contact.page';
import { PremiumPage } from '@app/modals/premium/premium.page';
import { FaqPage } from '@app/modals/faq/faq.page';
import { ExplicationPage } from '@app/modals/explication/explication.page';
import { InstallPage } from '@app/modals/install/install.page';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(public services: ServicesService) {}

  public async openFaq() {
    const modal = await this.services.modalController.create({
      component: FaqPage,
      componentProps: {},
    });
    modal.present();
  }

  public async openInstall() {
    const modal = await this.services.modalController.create({
      component: InstallPage,
      componentProps: {},
    });
    modal.present();
  }

  public async openPremium() {
    const modal = await this.services.modalController.create({
      component: PremiumPage,
      cssClass: ['big-modal'],
      componentProps: {},
    });
    modal.present();
  }

  public async openContact() {
    const modal = await this.services.modalController.create({
      component: ContactPage,
      componentProps: {},
    });
    modal.present();
  }

  public async openExplication(
    title: string,
    content: string,
    image_or_icon: string = null
  ) {
    const modal = await this.services.modalController.create({
      component: ExplicationPage,
      componentProps: {
        title: title,
        content: content,
        image_or_icon: image_or_icon,
      },
    });
    modal.present();
  }
}
