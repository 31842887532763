import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { GeneralGuardService } from './guards/general-guard.service';

const routes: Routes = [
  {
    path: 'suivi',
    loadChildren: () =>
      import('./pages/suivi/suivi.module').then((m) => m.SuiviPageModule),
    canActivate: [GeneralGuardService],
  },
  {
    path: 'evolution',
    loadChildren: () =>
      import('./pages/evolution/evolution.module').then(
        (m) => m.EvolutionPageModule
      ),
    canActivate: [GeneralGuardService],
  },
  {
    path: 'calendrier',
    loadChildren: () =>
      import('./pages/calendrier/calendrier.module').then(
        (m) => m.CalendrierPageModule
      ),
    canActivate: [GeneralGuardService],
  },
  {
    path: 'couple',
    loadChildren: () =>
      import('./pages/couple/couple.module').then((m) => m.CouplePageModule),
    canActivate: [GeneralGuardService],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [GeneralGuardService],
  },
  {
    path: '**',
    redirectTo: '/suivi',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
