export class Debug {
  public static logConsole: boolean = false;
  public static forcePopover: boolean = false;
  public static forceNativeIOS: boolean = false;
  public static forceIOS: boolean = false;
  public static localhost_server: boolean = false;
  public static show_bullet: boolean = false;
  public static disable_trial: boolean = false;
  public static ios_publication_mode: boolean = false;
  public static disable_premium: boolean = false;
  public static generate_new_data: boolean = false;
  public static log(message: string) {
    if (this.logConsole) {
      console.log(message);
    }
  }
}
