import {
  Renderer2,
  Component,
  ChangeDetectorRef,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { ServicesService } from '@app/services/services.service';

@Component({
  selector: 'app-popover-info',
  templateUrl: './popover-info.component.html',
  styleUrls: ['./popover-info.component.scss'],
})
export class PopoverInfoComponent implements OnInit {
  @Input() message: string = null;
  @Input() function: any = null;

  @Input() target: any = null;
  @Input() options: any = null;
  @Input() content: any = null;

  placement: string = 'top';
  @ViewChild('container', { read: ElementRef, static: false })
  container: ElementRef;

  @ViewChild('div', { read: ElementRef, static: false }) div: ElementRef;
  destroyPopoverEvent: any;
  updatePositionPopoverEvent: any;
  constructor(
    private renderer: Renderer2,
    public cdr: ChangeDetectorRef,
    public services: ServicesService,
    public element: ElementRef
  ) {}

  ngOnInit() {
    this.cdr.detectChanges();
    this.destroyPopoverEvent = this.services.eventService.subscribe(
      'destroyPopover',
      (data: any) => {
        this.clean();

        this.element.nativeElement.remove();
      }
    );

    this.updatePositionPopoverEvent = this.services.eventService.subscribe(
      'updatePositionPopover',
      (data: any) => {
        this.calculatePosition();
      }
    );

    window.addEventListener('click', this.onClickOutside);
  }

  onClickOutside = (e) => {
    this.destroy();
  };

  clean() {
    this.destroyPopoverEvent.unsubscribe();
    this.updatePositionPopoverEvent.unsubscribe();
    clearInterval(this.interval);
  }

  ngOnDestroy() {
    window.removeEventListener('click', this.onClickOutside);
    this.clean();
  }

  destroy() {
    if (this.options && this.options['function']) {
      this.options['function']();
    }
    this.clean();

    this.element.nativeElement.remove();
  }

  interval: any;

  getOffset(element) {
    let top = 0,
      left = 0;
    let height = element.height;
    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;

      element = element.offsetParent;
    } while (element);

    return {
      top: top,
      left: left,
      bottom: top + height,
    };
  }

  hasSomeParentTheClass(element, classname) {
    if (
      element.className != null &&
      element.className.split(' ').indexOf(classname) >= 0
    )
      return true;
    return (
      element.parentNode &&
      this.hasSomeParentTheClass(element.parentNode, classname)
    );
  }

  calculatePosition() {
    this.interval = setInterval(() => {
      if (!document.body.contains(this.target)) {
        //console.log("destroy");
        this.clean();
        this.element.nativeElement.remove();
      }
    }, 1000);

    this.renderer.setStyle(this.element.nativeElement, 'opacity', '0', 2);
    const refreshInterval = setInterval(() => {
      this.placement = 'top';
      let modal = this.hasSomeParentTheClass(this.target, 'modal-wrapper');

      let element = this.container.nativeElement.getBoundingClientRect();

      let yContent = 0;
      let xContent = 0;

      let y = this.getOffset(this.target).top - element.height;
      let x =
        this.getOffset(this.target).left +
        this.target.getBoundingClientRect().width / 2;
      if (this.options && this.options.manualX) {
        x = this.options.manualX;
      }

      if (modal != null) {
        let screen_size = this.services.menuService.screenSize;
        let minus_x = 0;
        if (screen_size > 800) {
          minus_x = (screen_size - 800) / 2;
        }
        x -= minus_x;
      }

      if (this.options && this.options.manualY) {
        y = this.options.manualY - element.height;
        if (this.options && this.options.forceBottom) {
          this.placement = 'bottom';
        }
      } else if (
        (y < 60 && !this.services.menuService.isMobile) ||
        (y < 5 && this.services.menuService.isMobile) ||
        (this.options && this.options.forceBottom)
      ) {
        this.placement = 'bottom';
        y =
          this.getOffset(this.target).top +
          this.target.getBoundingClientRect().height;
      }

      if (x + element.width / 2 > window.innerWidth - 30) {
        let diff = x + element.width / 2 - window.innerWidth + 5;
        diff = this.services.utilsService.clamp(diff, -130, 130);

        this.renderer.setStyle(
          this.div.nativeElement,
          'transform',
          'translate(' + -diff + 'px,0px)',
          2
        );
      }
      if (x - element.width / 2 < 10) {
        let diff = (x - element.width / 2) * -1 + 20;
        diff = this.services.utilsService.clamp(diff, -130, 130);
        this.renderer.setStyle(
          this.div.nativeElement,
          'transform',
          'translate(' + diff + 'px,0px)',
          2
        );
      }

      if (this.options && this.options.addY) {
        y += this.options.addY;
      }

      //  console.log("INTERVAL");
      this.renderer.setStyle(this.element.nativeElement, 'opacity', '1', 2);

      this.renderer.setStyle(
        this.element.nativeElement,
        'position',
        'absolute',
        2
      );
      this.renderer.setStyle(this.element.nativeElement, 'z-index', '6000', 2);
      this.renderer.setStyle(
        this.element.nativeElement,
        'top',
        y - yContent + 'px',
        2
      );
      this.renderer.setStyle(
        this.element.nativeElement,
        'left',
        x - xContent + 'px',
        2
      );
      if (x != 0 || y != 0) {
        clearInterval(refreshInterval);
      }
    }, 100);
  }

  ngAfterViewInit() {
    this.calculatePosition();
  }
}
