<div class="globalContainer" #container (click)="destroy()">
  <div
    *ngIf="placement == 'bottom'"
    class="flex-col-center"
    style="background: transparent"
  >
    <div class="arrow-up"></div>
  </div>

  <div #div class="container">
    <div class="buttonClose flex-col-center">
      <ion-icon slot="end" name="close"> </ion-icon>
    </div>
    <p
      *ngIf="
        options &&
        options['icon'] &&
        !options['id_video'] &&
        !options['src_image']
      "
      class="ion-text-center pIcon"
    >
      <ion-icon
        *ngIf="!options['icon'].includes('asset')"
        size="large"
        color="white"
        name="{{ options['icon'] }}"
      ></ion-icon>
      <ion-icon
        *ngIf="options['icon'].includes('asset')"
        size="large"
        color="white"
        src="{{ options['icon'] }}"
      ></ion-icon>
    </p>

    <ng-container *ngIf="options && options['src_image']">
      <div class="image_container">
        <ion-img [src]="options['src_image']"></ion-img>
      </div>
    </ng-container>

    <p class="message whiteB" [innerHtml]="message | translate"></p>

    <!--<div class="allWidth flex-col-center ion-margin-top">
      <ion-button color="white" shape="round" fill="outline">
        Fermer
        <ion-icon slot="end" name="close"> </ion-icon>
      </ion-button>
    </div>-->
  </div>

  <div
    *ngIf="placement == 'top'"
    class="flex-col-center"
    style="background: transparent"
  >
    <div class="arrow-down"></div>
  </div>
</div>
