import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { environment } from '@env/environment';
import { Router } from '@angular/router';

import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';
import { MenuService } from './menu.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, public httpClient: HttpClient) {}
  errors: string[] = [];

  handleError(error: any) {
    console.log('ERROR DETECTED');
    const router = this.injector.get(Router);

    const utilsService = this.injector.get(UtilsService);
    const apiService = this.injector.get(ApiService);
    const menuService = this.injector.get(MenuService);

    let stack = error.stack;
    let message: string = error.message;
    console.error(error.stack.toString());

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const tradFailedMessage = /pipe.*translate/;
    if (
      chunkFailedMessage.test(error.message) ||
      tradFailedMessage.test(error.message)
    ) {
      window.location.reload();
      return;
    }

    if (message.includes('overlay')) {
      return;
    }

    if (!this.errors.includes(message)) {
      this.errors.push(message);
      var data = JSON.stringify({
        message: message,
        stack: stack,
        navigator:
          'URL : ' +
          menuService.currentPage +
          ' - Nav : ' +
          utilsService.getNavigator() +
          ' - V: ' +
          environment.appVersion,
      });
      apiService.post('error', data).subscribe((data: any) => {});
    }
  }
}
