<ion-app>
  <ion-router-outlet>
    <div #loadingElement class="app-loading containerLoading">
      <svg class="spinner" viewBox="25 25 50 50">
        <circle
          class="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="2"
          stroke-miterlimit="10"
          style="color: white"
        />
      </svg>
      <p style="color: white">{{ "loading" | translate }}...</p>
    </div>
  </ion-router-outlet>

  <ion-header
    class="menuDesktop"
    *ngIf="
      !this.services.menuService.isMobile &&
      this.services.menuService.menuDisplayed
    "
  >
    <ion-toolbar>
      <ion-row class="ion-align-items-center">
        <ion-col size="3" class="flex-col-center colButton" routerLink="/">
          <img src="assets/images/logo/logo_vertical_bi.png" />
        </ion-col>
        <ion-col size="9">
          <ion-row>
            <ion-col
              class="flex-col-center colButton selected"
              routerLink="/suivi"
              [class.selected]="
                services.menuService.currentPageParent == 'suivi'
              "
            >
              <ion-icon src="assets/icon/star.svg"></ion-icon>
              <ion-label>{{ "menu.suivi" | translate }}</ion-label>
            </ion-col>
            <ion-col
              class="flex-col-center colButton"
              routerLink="/evolution"
              [class.selected]="
                services.menuService.currentPageParent == 'evolution'
              "
            >
              <ion-icon src="assets/icon/chart.svg"></ion-icon>
              <ion-label>{{ "menu.evolution" | translate }}</ion-label>
            </ion-col>

            <ion-col
              class="flex-col-center colButton"
              [class.selected]="
                services.menuService.currentPageParent == 'couple'
              "
              routerLink="/couple"
            >
              <ion-icon src="assets/icon/user.svg"></ion-icon>
              <ion-label>{{ "menu.psychology" | translate }}</ion-label>
            </ion-col>
            <ion-col
              class="flex-col-center colButton"
              [class.selected]="
                services.menuService.currentPageParent == 'calendrier'
              "
              routerLink="/calendrier"
            >
              <ion-icon src="assets/icon/calendar.svg"></ion-icon>
              <ion-label>{{ "menu.missions" | translate }}</ion-label>
            </ion-col>
          </ion-row>
        </ion-col>
        <div
          class="settingsButton flex-col-center hover"
          [class.selectedSettingsButton]="
            services.menuService.currentPageParent == 'profile'
          "
          routerLink="/profile"
        >
          <ion-icon name="settings-outline"></ion-icon>
        </div>

        <!--<ion-col size="1" size-xl="3">
          <ion-col class="flex-col-center" style="justify-content: end">
            <div
              class="settingsButton flex-col-center hover"
              [class.selected]="
                services.menuService.currentPageParent == 'profile'
              "
              routerLink="/profile"
            >
              <ion-icon name="settings"></ion-icon>
            </div>
          </ion-col>
        </ion-col>-->
      </ion-row>
    </ion-toolbar>
  </ion-header>
  <ion-footer
    class="menuMobile"
    *ngIf="
      this.services.menuService.isMobile &&
      this.services.menuService.menuDisplayed
    "
  >
    <ion-toolbar>
      <ion-row class="ion-align-items-center flex-col-center">
        <ion-col
          class="flex-col-vertical-center colButton selected"
          routerLink="/suivi"
          [class.selected]="services.menuService.currentPageParent == 'suivi'"
        >
          <ion-icon src="assets/icon/star.svg"></ion-icon>
          <ion-label>{{ "menu.suivi" | translate }}</ion-label>
        </ion-col>
        <ion-col
          class="flex-col-vertical-center colButton"
          routerLink="/evolution"
          [class.selected]="
            services.menuService.currentPageParent == 'evolution'
          "
        >
          <ion-icon src="assets/icon/chart.svg"></ion-icon>
          <ion-label>{{ "menu.evolution" | translate }}</ion-label>
        </ion-col>
        <ion-col
          [class.selected]="services.menuService.currentPageParent == 'couple'"
          routerLink="/couple"
          class="flex-col-vertical-center colButton"
        >
          <ion-icon src="assets/icon/user.svg"></ion-icon>
          <ion-label>{{ "menu.psychology" | translate }}</ion-label>
        </ion-col>
        <ion-col
          [class.selected]="
            services.menuService.currentPageParent == 'calendrier'
          "
          routerLink="/calendrier"
          class="flex-col-vertical-center colButton"
        >
          <ion-icon src="assets/icon/calendar.svg"></ion-icon>
          <ion-label>{{ "menu.missions" | translate }}</ion-label>
        </ion-col>
      </ion-row>

      <div
        class="settingsButton flex-col-center hover"
        [class.selectedSettingsButton]="
          services.menuService.currentPageParent == 'profile'
        "
        routerLink="/profile"
      >
        <ion-icon name="settings-outline"></ion-icon>
      </div>
    </ion-toolbar>
  </ion-footer>
</ion-app>
