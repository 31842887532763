<ion-content>
  <div class="background-image">
    <ion-img src="{{randomImage}}"></ion-img>
    <div class="gradient"></div>
    <div class="gradient2"></div>
  </div>

  <ion-fab vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button color="white" size="small" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <div class="container">
    <div class="middle allWidth">
      <ng-container *ngIf="mode == 'buy'">
        <div
          class="trial-header allWidth"
          style="background-color: var(--ion-color-success)"
          *ngIf="this.services.sessionService.free_trial != null && !this.services.sessionService.has_buyed && !this.options.disable_trial"
        >
          {{'premium.enjoyTrial' |
          translate:{day:this.services.utilsService.ceil(this.services.sessionService.free_trial.date_staying)}
          }}
        </div>

        <!--<div
          class="trial-header allWidth"
          *ngIf="this.services.sessionService.free_trial == null && !this.services.sessionService.has_buyed"
        >
          {{'premium.trialFinished' | translate }}
        </div>-->

        <h1 class="ion-double-margin-top">{{'premium.title' | translate}}</h1>

        <app-divider></app-divider>

        <div class="allWidth first-explication">
          <ion-img
            class="img-giorgio"
            src="assets/images/characters/specific/cupidon.png"
          ></ion-img>
        </div>

        <div class="allWidth list-argument">
          <ion-list class="ion-padding-start ion-padding-end">
            <ion-item lines="full">
              <ion-icon
                color="white"
                name="checkmark-outline"
                slot="start"
              ></ion-icon>
              <ion-label>{{'premium.missionArgument' | translate }}</ion-label>
            </ion-item>
            <ion-item lines="full">
              <ion-icon
                color="white"
                name="checkmark-outline"
                slot="start"
              ></ion-icon>
              <ion-label>{{'premium.testArgument' | translate }}</ion-label>
            </ion-item>
            <ion-item lines="full">
              <ion-icon
                color="white"
                name="checkmark-outline"
                slot="start"
              ></ion-icon>
              <ion-label
                >{{'premium.algorithmArgument' | translate }}</ion-label
              >
            </ion-item>
            <ion-item lines="full">
              <ion-icon
                color="white"
                name="checkmark-outline"
                slot="start"
              ></ion-icon>
              <ion-label>{{'premium.dataArgument' | translate }}</ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-icon
                color="white"
                name="checkmark-outline"
                slot="start"
              ></ion-icon>
              <ion-label
                >{{'premium.statisticsArgument' | translate }}</ion-label
              >
            </ion-item>
          </ion-list>
        </div>

        <div
          class="allWidth flex-col-vertical-center ion-margin-top"
          *ngIf="all_currencies"
        >
          <div class="currency-change">
            <ion-select
              (ionChange)="loadPrice($event.detail.value)"
              interface="popover"
              value="{{current_currencie}}"
              placeholder="{{'premium.changeCurrencie'}}"
            >
              <ion-select-option
                *ngFor="let currency of all_currencies"
                [value]="currency"
                >{{this.services.utilsService.toUppercase(currency)}}</ion-select-option
              >
            </ion-select>
          </div>
        </div>

        <app-loading [object]="price"></app-loading>
        <ion-row class="containerPricing" *ngIf="price">
          <ion-col size="12" size-lg="4">
            <ion-card class="hover" (click)="buy('subscription_monthly')">
              <ion-card-header>
                <ion-card-title
                  >{{'premium.subscription_monthly' |
                  translate}}</ion-card-title
                >

                <ion-card-subtitle *ngIf="price">
                  <span
                    >{{this.price.subscription_monthly |
                    price:{currency:this.current_currencie} }}</span
                  >

                  {{'premium.everyMonth' | translate}}</ion-card-subtitle
                >
              </ion-card-header>

              <div class="allWidth flex-col-center ion-margin-bottom">
                <ion-button color="primary-gradient" shape="round" size="large">
                  {{'premium.buy' | translate}}
                  <ion-icon
                    name="chevron-forward-outline"
                    slot="end"
                  ></ion-icon>
                </ion-button>
              </div>
            </ion-card>
          </ion-col>
          <ion-col size="12" size-lg="4">
            <ion-card class="hover" (click)="buy('subscription_yearly')">
              <ion-card-header>
                <div class="discount">
                  <span>
                    -{{this.services.utilsService.roundN(
                    this.price.subscription_yearly /
                    (this.price.subscription_monthly * 12) * 100,0) }}%</span
                  >
                </div>

                <ion-card-title
                  >{{'premium.subscription_yearly' | translate}}</ion-card-title
                >

                <ion-card-subtitle *ngIf="price">
                  <span
                    >{{this.services.utilsService.roundN(this.price.subscription_yearly
                    , 2) | price:{currency:this.current_currencie} }}</span
                  >

                  {{'premium.everyYear' | translate}}</ion-card-subtitle
                >
                <!--<p class="price-yearly">
                  ({{this.services.utilsService.roundN((this.price.subscription_yearly
                  - this.price.subscription_yearly * (discount.percentage /
                  100)),2) | price:{currency:this.current_currencie} }} /
                  {{'premium.everyYear' | translate}})
                </p>-->
              </ion-card-header>

              <div class="allWidth flex-col-center ion-margin-bottom">
                <ion-button color="primary-gradient" shape="round" size="large">
                  {{'premium.buy' | translate}}
                  <ion-icon
                    name="chevron-forward-outline"
                    slot="end"
                  ></ion-icon>
                </ion-button>
              </div>
            </ion-card>
          </ion-col>

          <ion-col size="12" size-lg="4">
            <ion-card class="hover" (click)="buy('lifetime')">
              <ion-card-header>
                <ion-card-title
                  >{{'premium.lifetime' | translate}}</ion-card-title
                >

                <ion-card-subtitle *ngIf="price">
                  <span
                    >{{this.price.lifetime |
                    price:{currency:this.current_currencie} }}</span
                  >

                  {{'premium.unique_buy' | translate}}</ion-card-subtitle
                >
              </ion-card-header>

              <div class="allWidth flex-col-center ion-margin-bottom">
                <ion-button color="primary-gradient" shape="round" size="large">
                  {{'premium.buy' | translate}}
                  <ion-icon
                    name="chevron-forward-outline"
                    slot="end"
                  ></ion-icon>
                </ion-button>
              </div>
            </ion-card>
          </ion-col>
        </ion-row>

        <ion-img
          class="ion-margin-bottom ion-padding"
          style="max-width: 400px; margin: auto"
          src="assets/images/premium/banniere-paiement-disponible.png"
        ></ion-img>

        <h2 class="ion-text-center" style="margin-top: 50px">
          {{'premium.joinTheTeam' | translate}}
        </h2>

        <ion-img
          class="img-giorgio"
          src="assets/images/characters/specific/star.png"
        ></ion-img>

        <ion-row class="container-review">
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <div class="interior-review flex-col-vertical-center">
              <p
                class="ion-text-center"
                [innerHtml]="'premium.review1' | translate"
              ></p>
              <span class="ion-text-center">Greg</span>
            </div>
          </ion-col>
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <div class="interior-review flex-col-vertical-center">
              <p
                class="ion-text-center"
                [innerHtml]="'premium.review2' | translate"
              ></p>
              <span class="ion-text-center">Alan</span>
            </div>
          </ion-col>
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <div class="interior-review flex-col-vertical-center">
              <p
                class="ion-text-center"
                [innerHtml]="'premium.review3' | translate"
              ></p>
              <span class="ion-text-center">Sarah</span>
            </div>
          </ion-col>
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <div class="interior-review flex-col-vertical-center">
              <p
                class="ion-text-center"
                [innerHtml]="'premium.review4' | translate"
              ></p>
              <span class="ion-text-center">Johnny</span>
            </div>
          </ion-col>
        </ion-row>
        <h2 class="ion-text-center">{{'premium.someQuestions' | translate}}</h2>

        <ion-img
          class="img-giorgio"
          src="assets/images/characters/specific/math.png"
        ></ion-img>
        <ion-row class="container-questions">
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <span class="title-question"
              >« {{'premium.questionTitle5' | translate}} »</span
            >
            <div class="container-response flex-col-vertical-center">
              <div class="icon flex-col-vertical-center">
                <ion-icon name="enter"></ion-icon>
              </div>
              <span class="title-response"
                >{{'premium.responseTitle5' | translate}}</span
              >
              <p [innerHtml]="'premium.response5' | translate"></p>
            </div>
          </ion-col>
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <span class="title-question"
              >« {{'premium.questionTitle6' | translate}} »</span
            >
            <div class="container-response flex-col-vertical-center">
              <div class="icon flex-col-vertical-center">
                <ion-icon name="save"></ion-icon>
              </div>
              <span class="title-response"
                >{{'premium.responseTitle6' | translate}}</span
              >
              <p [innerHtml]="'premium.response6' | translate"></p>
            </div>
          </ion-col>
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <span class="title-question"
              >« {{'premium.questionTitle1' | translate}} »</span
            >
            <div class="container-response flex-col-vertical-center">
              <div class="icon flex-col-vertical-center">
                <ion-icon name="infinite"></ion-icon>
              </div>
              <span class="title-response"
                >{{'premium.responseTitle1' | translate}}</span
              >
              <p [innerHtml]="'premium.response1' | translate"></p>
            </div>
          </ion-col>
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <span class="title-question"
              >« {{'premium.questionTitle2' | translate}} »</span
            >
            <div class="container-response flex-col-vertical-center">
              <div class="icon flex-col-vertical-center">
                <ion-icon name="shield-checkmark"></ion-icon>
              </div>
              <span class="title-response"
                >{{'premium.responseTitle2' | translate}}</span
              >
              <p [innerHtml]="'premium.response2' | translate"></p>
            </div>
          </ion-col>
          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <span class="title-question"
              >« {{'premium.questionTitle3' | translate}} »</span
            >
            <div class="container-response flex-col-vertical-center">
              <div class="icon flex-col-vertical-center">
                <ion-icon name="refresh"></ion-icon>
              </div>
              <span class="title-response"
                >{{'premium.responseTitle3' | translate}}</span
              >
              <p [innerHtml]="'premium.response3' | translate"></p>

              <ion-img
                class="ion-margin-bottom"
                style="
                  max-width: 400px;
                  margin: auto;
                  border-radius: 10px;
                  overflow: hidden;
                "
                src="assets/images/premium/update-subscription.gif"
              ></ion-img>
            </div>
          </ion-col>

          <ion-col size="12" size-md="6" class="flex-col-vertical-center">
            <span class="title-question"
              >« {{'premium.questionTitle4' | translate}} »</span
            >
            <div class="container-response flex-col-vertical-center">
              <div class="icon flex-col-vertical-center">
                <ion-icon name="cash"></ion-icon>
              </div>
              <span class="title-response"
                >{{'premium.responseTitle4' | translate}}</span
              >
              <p [innerHtml]="'premium.response4' | translate"></p>

              <ion-img
                class="ion-margin-bottom"
                style="
                  max-width: 400px;
                  margin: auto;
                  border-radius: 10px;
                  overflow: hidden;
                "
                src="assets/images/premium/new-relationship.gif"
              ></ion-img>
            </div>
          </ion-col>
        </ion-row>

        <h2 class="ion-text-center">{{'premium.otherQuestion' | translate}}</h2>
        <p class="ion-text-center ion-padding-start ion-padding-end">
          {{'premium.contactExplication' | translate}}
        </p>

        <div class="allWidth flex-col-vertical-center ion-margin-bottom">
          <ion-button
            color="primary-gradient"
            shape="round"
            class="ion-margin-bottom"
            (click)="this.services.modalService.openFaq();"
          >
            {{'profil.faq' | translate}}
            <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
          </ion-button>
          <ion-button
            color="primary-gradient"
            shape="round"
            (click)="openContact()"
          >
            {{'premium.contact' | translate}}
            <ion-icon name="send-outline" slot="end"></ion-icon>
          </ion-button>
        </div>

        <div class="allWidth flex-col-center ion-margin-top ion-padding-bottom">
          <ion-text color="medium">
            <span class="underline hover" (click)="openSaleAgreement()"
              >{{'premium.saleAgreement' | translate}}</span
            >
          </ion-text>
        </div>
      </ng-container>
      <ng-container *ngIf="mode == 'success'">
        <div class="allWidth success">
          <ion-img
            style="max-width: 350px; margin: auto"
            src="assets/images/characters/specific/gourmand.png"
          ></ion-img>
          <p
            class="ion-text-center"
            [innerHtml]="'premium.success' | translate"
          ></p>
          <div class="flex-col-center allWidth">
            <ion-button
              color="primary-gradient"
              shape="round"
              (click)="dismiss()"
            >
              {{'premium.close' | translate}}
              <ion-icon name="checkmark" slot="end"></ion-icon>
            </ion-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ion-content>
