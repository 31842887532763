import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DateService } from './date.service';
import { UtilsService } from './utils.service';
import { StorageService } from './storage.service';
import { EventService } from './event.service';
import { Debug } from '@app/class/debug';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from './menu.service';
import { EncryptionService } from './encryption.service';
import { SessionService } from './session.service';
import { DataService } from './data.service';
import { GiorgioService } from './giorgio.service';
import { AiService } from './ai.service';
import {
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { BrowserService } from './browser.service';
import { ModalService } from './modal.service';
import { PushNotificationService } from './push-notification.service';
import { DataLayerService } from './data-layer.service';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  public apiService!: ApiService;
  public dateService!: DateService;
  public utilsService!: UtilsService;
  public storageService!: StorageService;
  public eventService!: EventService;
  public translateService!: TranslateService;
  public menuService!: MenuService;
  public encryptionService!: EncryptionService;
  public sessionService!: SessionService;
  public dataService!: DataService;
  public giorgioService!: GiorgioService;
  public modalService!: ModalService;
  public aiService!: AiService;
  public browserService!: BrowserService;
  public debug!: Debug;
  public pushNotificationService!: PushNotificationService;
  public dataLayerService!: DataLayerService;

  constructor(
    public toastController: ToastController,
    public alertController: AlertController,
    public modalController: ModalController
  ) {}
}
